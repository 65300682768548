/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'
import { KTSVG } from "../../../../_metronic/helpers"

type NewType = {
    className: string
    color: string
    svgIcon: string
    iconColor: string
    title: string
    titleColor?: string
    description: string
    descriptionColor?: string
    href?: string
}

type Props = NewType

const DashboardWidget: React.FC<Props> = ({
  className,
  color,
  svgIcon,
  iconColor,
  title,
  titleColor,
  description,
  descriptionColor,
  href
}) => {
  return (
    <div className={`card card-body bg-gray-200 hoverable ${className}`}>
      <KTSVG path={svgIcon} className={`svg-icon-${iconColor} svg-icon-3x ms-n1`} />

        <div className={`text-${titleColor} fw-bold fs-2 mb-2 mt-5`}>{title}</div>

        <div className={`fw-semibold text-${descriptionColor}`}>
          { href !== undefined && href !== '' ? <a href={href} target="_blank" rel="noreferrer">{description}</a> : <>{description}</> }
        </div>
    </div>
  )
}

export { DashboardWidget }
