/* eslint-disable jsx-a11y/anchor-is-valid */
import {useState, useEffect} from 'react'
/*import * as Yup from 'yup'
import clsx from 'clsx'
import {Link} from 'react-router-dom'
import {useFormik} from 'formik'*/
import {loginRequest, loginRequestSigned} from '../core/_requests'
import {toAbsoluteUrl} from '../../../../_metronic/helpers'
import {useAuth} from '../core/Auth'
import { ethers } from 'ethers'
import { useEthers } from '../../ethers/ethersProvider'
import { KTSVG } from "../../../../_metronic/helpers"
import { Container, Row, Col, Button, Alert } from "react-bootstrap";

/*import {Navigate, Routes} from 'react-router-dom'
import detectEthereumProvider from '@metamask/detect-provider'*/

/*
const loginSchema = Yup.object().shape({
  email: Yup.string()
    .email('Wrong email format')
    .min(3, 'Minimum 3 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('Email is required'),
  password: Yup.string()
    .min(3, 'Minimum 3 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('Password is required'),
})

const initialValues = {
  email: 'admin@demo.com',
  password: 'demo',
}
*/
/*
  Formik+YUP+Typescript:
  https://jaredpalmer.com/formik/docs/tutorial#getfieldprops
  https://medium.com/@maurice.de.beijer/yup-validation-and-typescript-and-formik-6c342578a20e
*/

export function Login() {
  const [loading, setLoading] = useState(false)
  const {saveAuth, setCurrentUser} = useAuth()
  const { connect, connected, provider, signer, address } = useEthers()
  const [alertData, setAlertData] = useState({show: false, message: '', variant: 'success'})

  useEffect(() => {
    return () => {

    }
  }, [])
  
     const connectMetamask = async function() {
      try {
        if (window.ethereum === undefined) {
          setAlertData({show: true, variant: 'danger', message: 'Web3 wallet not found'})
          return
        } else {
          connect()
        }
      } catch (e: any) {
        setAlertData({show: true, variant: 'danger', message: e.message})
      }
    }

    
   async function SignMessageLogin() {
      try {    
        setLoading(true)

        if (signer === undefined) throw new Error("Signer not found!")
        const sign: ethers.providers.JsonRpcSigner = signer

        const addr:string = address === undefined ? '' : address

        const loginRequestResponse:any = (await loginRequest(addr)).data;
        const challenge: string = loginRequestResponse.challenge
        const message: string = `https://decentralizing.finance/\nDeFiDemo Login\nChallenge ${challenge}`
        const signature: string = await sign.signMessage(message)
        
        console.log(loginRequestResponse)
        const data2:any = (await loginRequestSigned(addr, message, signature)).data
        if (data2.result) {
          const userAuth = Object(data2.data)
          saveAuth(userAuth)
          setCurrentUser(userAuth)
          alertData.message = 'Success!'
          alertData.variant = 'success'
        } else {
          alertData.variant = 'danger'
          alertData.message = data2.reason
        }     
      } catch (e: any) {
        alertData.variant = 'danger'
        alertData.message = e.message
      }
      alertData.show = true
      setAlertData(alertData)
      setLoading(false)
  }

  return (

      <>
      <div className='w-100'>
        <div className='text-center mb-10'>
        
        <h1>DeFiDemo</h1>
        <div className='container-fluid'>
            {/*<Row>
              <Col>
                <img src='/media/logos/default-dark.png' alt='DeFiDemo'></img>
              </Col>
            </Row>*/}
            <Row>
              <Col>
              <p className={`mb-10`}>powered by Web3</p>
              <p className='mb-4 fw-bold'>Compatible wallets</p>
              </Col>
            </Row>
            <div className='row mb-4'>
              <div className='col'><img src="assets/media/svg/custom/metamask-fox-wordmark-stacked.svg" style={{maxWidth: '100px'}} alt="MetaMask logo" /></div>
              <div className='col'><img src="assets/media/svg/custom/crypto-com-vector-logo.svg" style={{maxWidth: '100px'}} alt="Crypto.com logo" /></div>
            </div>
            <div className='row mb-8'>
              <div className='col'><img src="assets/media/svg/custom/Coinbase-logo-square-1.svg" style={{maxWidth: '50px'}} alt="Coinbase wallet logo" /></div>
              <div className='col'>..and more</div>
            </div>
          </div>
          {/*<h1 className='text-gray-600 mb-3'>Web3 Login</h1>*/}
          <div className='fw-bold fs-5'>
            <p className={'text-gray-700'}>A cryptographic signature is required for login.</p>
          </div>
        </div>
        
        <div className='text-center'>
        { alertData.show  &&
          <Alert variant={alertData.variant} onClose={() => { setAlertData({...alertData, show: false})}} dismissible>
              {alertData.message}
          </Alert>
        }
        { !connected ? 
        <button
            type='button'
            //id='kt_sign_in_submit'
            className='btn btn-lg btn-primary w-100 mb-5'
            onClick={connectMetamask}
            //disabled={formik.isSubmitting || !formik.isValid}
          >Connect Web3</button> :       <button
          type='button'
          //id='kt_sign_in_submit'
          className='btn btn-lg btn-primary w-100 mb-5'
          onClick={SignMessageLogin}
          //disabled={formik.isSubmitting || !formik.isValid}
        >
          {!loading && <span className='indicator-label'>Sign Message</span>}
          {loading && (
            <span className='indicator-progress' style={{display: 'block'}}>
              Waiting for signature...
              <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
            </span>
          )}
        </button>
        }

        </div>

      </div>
    </>
  
    
  )
}
