import React  from "react"
import { PageTitle } from "../../../_metronic/layout/core"
import { useAuth } from "../../modules/auth";
import { EthersProvider, useEthers } from "../../modules/ethers/ethersProvider";
import { ethers } from "ethers";
const MyPage:React.FC = () => {
    const { currentUser } = useAuth()
    const { connect, isConnected, connected, address, provider, signer, block, chainId, balance } = useEthers()

    React.useEffect(() => {
        if (!connected) connect()
    }, [address, connected])
    
    const str = `Connected as ${currentUser?.crypto_address}`
    const title: string = `My page - ${str}`
return (
    <>
        <PageTitle>{title}</PageTitle>
        <div>
            <h1>My Page</h1>
            <p>Address {address}</p>
            <p>Connected (RPC) {isConnected() ? 'true' : 'false'}</p>
            <p>Accounts available {connected ? 'true' : 'false'}</p>
            <p>Chain ID {Number(chainId)}</p>
            <p>Block number {Number(block)}</p>
            <p>Balance {balance !== undefined ? ethers.utils.formatUnits(balance) : '0'} ETH</p>
        </div>

        
    </>
  )
}

export {MyPage};

/*
import { ethers } from "ethers";
import React from "react";

class MyPage extends React.Component {
    render() {
        return <div><h1>Hello, this is a test!</h1></div>;
    }
}

export { MyPage }
*/