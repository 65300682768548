import React  from "react"
import { PageTitle } from "../../../_metronic/layout/core"
import { useAuth } from "../../modules/auth";
import { EthersProvider, useEthers } from "../../modules/ethers/ethersProvider";
import { ethers } from "ethers";
import { Container, Row, Col } from "react-bootstrap";
import { DashboardWidget } from "./components/DashboardWidget";
import '../../../_metronic/assets/css/cardBorders.css';
import { useThemeMode } from "../../../_metronic/partials";
const Dashboard:React.FC = () => {
    const { currentUser } = useAuth()
    const { connect, isConnected, connected, address, provider, signer, block, chainId, balance, chainData } = useEthers()
    const {mode, menuMode, updateMode, updateMenuMode} = useThemeMode()

    React.useEffect(() => {
        if (!connected) connect()
    }, [address, connected])
    
    React.useEffect(() => {

    }, [chainData])

    const pageTitle = `Dashboard`
    
return (
    <>
        <PageTitle>{pageTitle}</PageTitle>
        <Container fluid>
            <Row style={{textAlign: 'center'}} >
                <div className="col-xl-4 mb-4">
                    <DashboardWidget
                        className={`card-xl-stretch mb-xl-8 card-borders ${mode === 'dark' ? 'card-gradient' : ''}`}
                        svgIcon='/media/icons/duotune/finance/fin001.svg'
                        color='body-white'
                        iconColor='primary'
                        title='Address'
                        description={address === undefined ? 'unknown' : address}
                        titleColor='gray-900'
                        descriptionColor='gray-400'
                        href={address === undefined || chainId === undefined || chainData === undefined || chainData.explorers[0] === undefined  ? '' : `${chainData.explorers[0].url}/address/${address}`}
                    />
                </div>
                <div className="col-xl-3 mb-4">
                    <DashboardWidget
                        className={`card-xl-stretch mb-xl-8 card-borders ${mode === 'dark' ? 'card-gradient' : ''}`}
                        svgIcon='/media/icons/duotune/finance/fin008.svg'
                        color='body-white'
                        iconColor='primary'
                        title='Balance'
                        description={balance !== undefined && chainData !== undefined ? `${ethers.utils.formatUnits(balance)} ${String(chainData.nativeCurrency.symbol)}` : '0'}
                        titleColor='gray-900'
                        descriptionColor='gray-400'
                        href={address === undefined || chainId === undefined || chainData === undefined || chainData.explorers[0] === undefined ? '' : `${chainData.explorers[0].url}/address/${address}`}
                    />
                </div>
                <div className="col-xl-3 mb-4">
                    <DashboardWidget
                        className={`card-xl-stretch mb-xl-8 card-borders ${mode === 'dark' ? 'card-gradient' : ''}`}
                        svgIcon='/media/icons/duotune/coding/cod007.svg'
                        color='body-white'
                        iconColor='primary'
                        title='Chain'
                        description={chainId === undefined || chainData === undefined ? '0' : `${String(chainData.name)} (${chainId})`}
                        titleColor='gray-900'
                        descriptionColor='gray-400'
                        href={chainId === undefined || chainData === undefined || chainData.explorers[0] === undefined   ? '' : chainData.explorers[0].url}
                    />
                </div>
                <div className="col-xl-2 mb-4">
                    <DashboardWidget
                        className={`card-xl-stretch mb-xl-8 card-borders ${mode === 'dark' ? 'card-gradient' : ''}`}
                        svgIcon='/media/icons/duotune/abstract/abs027.svg'
                        color='body-white'
                        iconColor='primary'
                        title='Latest Block'
                        description={block === undefined ? '0' : String(Number(block))}
                        titleColor='gray-900'
                        descriptionColor='gray-400'
                        href={chainId === undefined || chainData === undefined || chainData.explorers[0] === undefined  ? '' : `${chainData.explorers[0].url}/block/${String(Number(block))}`}
                    />
                </div>
            </Row>
        </Container>
        {/*<Container fluid>
            <Row style={{textAlign: 'center'}} className='row g-5 g-xl-8 mt-1'>
                <Col>
                    <DashboardWidget
                        className='card-xl-stretch mb-xl-8'
                        svgIcon='/media/icons/duotune/ecommerce/ecm002.svg'
                        color='body-white'
                        iconColor='primary'
                        title='Connected'
                        description={isConnected() ? 'Yes' : 'No'}
                        titleColor='gray-900'
                        descriptionColor='gray-400'
                    />
                </Col>
            </Row>
        </Container>
                    */}
    </>
  )
}

export { Dashboard };