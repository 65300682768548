import { useEffect, useRef, useState }  from "react"
import { PageTitle } from "../../../_metronic/layout/core"
import { useAuth } from "../../modules/auth";
import { Container, Row, Col, Form, Button, Alert } from "react-bootstrap";
import { KTSVG } from "../../../_metronic/helpers";
import { useEthers, shortenCryptoAddress } from "../../modules/ethers/ethersProvider";
import { BigNumber, ethers } from "ethers";
import Dropdown from 'react-bootstrap/Dropdown';
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import { toAbsoluteUrl } from "../../../_metronic/helpers";
import '../../../_metronic/assets/css/cardBorders.css';
import clsx from 'clsx'
import '../../../_metronic/assets/css/tabs.css'
import { useThemeMode } from "../../../_metronic/partials";
//token name
//token symbol
//initial supply
//circulating supply
//your balance

//mint / burn
interface UserTokenInterface {
    token: {
        tokenName: string,
        tokenSymbol: string,
        initialSupply: string,
    //    circulatingSupply: string,
    //    userBalance: string,
        address: string,
        maxMint: string
    }
}

interface ITokenContract extends ethers.Contract {
    balanceOf: (owner: string) => Promise<BigNumber>,
    totalSupply: () => Promise<BigNumber>,
    faucetMint: (amount: BigNumber) => Promise<void>
    mint: (to: string, amount: BigNumber) => Promise<void>
    paused: () => Promise<boolean>
    pauseToken: () => Promise<void>
    unpauseToken: () => Promise<void>
}
const tokenABI = ['constructor(uint256 initialSupply, string name, string symbol, address creatorAccount, address ownerAccount)',
'event Approval(address indexed owner, address indexed spender, uint256 value)',
'event Paused(address account)',
'event RoleAdminChanged(bytes32 indexed role, bytes32 indexed previousAdminRole, bytes32 indexed newAdminRole)',
'event RoleGranted(bytes32 indexed role, address indexed account, address indexed sender)',
'event RoleRevoked(bytes32 indexed role, address indexed account, address indexed sender)',
'event Transfer(address indexed from, address indexed to, uint256 value)',
'event Unpaused(address account)',
'function BURNER_ROLE() view returns (bytes32)',
'function DEFAULT_ADMIN_ROLE() view returns (bytes32)',
'function MINTER_ROLE() view returns (bytes32)',
'function _setMaxMint(uint256 amount)',
'function addMintAndBurn(address account)',
'function allowance(address owner, address spender) view returns (uint256)',
'function approve(address spender, uint256 amount) returns (bool)',
'function balanceOf(address account) view returns (uint256)',
'function burn(address from, uint256 amount)',
'function creator() view returns (address)',
'function decimals() view returns (uint8)',
'function decreaseAllowance(address spender, uint256 subtractedValue) returns (bool)',
'function destructToken() payable',
'function factory() view returns (address)',
'function faucetMint(uint256 amount)',
'function getRoleAdmin(bytes32 role) view returns (bytes32)',
'function grantRole(bytes32 role, address account)',
'function hasRole(bytes32 role, address account) view returns (bool)',
'function increaseAllowance(address spender, uint256 addedValue) returns (bool)',
'function maxMint() view returns (uint256)',
'function mint(address to, uint256 amount)',
'function name() view returns (string)',
'function owner() view returns (address)',
'function pauseToken()',
'function paused() view returns (bool)',
'function removeMintAndBurn(address account)',
'function renounceRole(bytes32 role, address account)',
'function revokeRole(bytes32 role, address account)',
'function supportsInterface(bytes4 interfaceId) view returns (bool)',
'function symbol() view returns (string)',
'function totalSupply() view returns (uint256)',
'function transfer(address recipient, uint256 amount) returns (bool)',
'function transferFrom(address sender, address recipient, uint256 amount) returns (bool)',
'function unpauseToken()'];

const UserToken:React.FC<UserTokenInterface> = (props) => {
    const { currentUser } = useAuth()
    const [tokenContract, setTokenContract] = useState<ITokenContract>()
    const { connect, isConnected, connected, address, provider, signer, block, chainId, balance, chainData } = useEthers()
    const [tokenBalance, setTokenBalance] = useState<string>('0.0')
    const [totalSupply, setTotalSupply] = useState<string>('0.0')
    const [alertData, setAlertData] = useState({tokenAction: {show: false, message: '', variant: 'success'}})
    const [loading, setLoading] = useState({tokenActionLoading: false})
    const [tab, setTab] = useState('Stats')

    // variables from mint, burn & pause section 
    const mbTargetAddress = useRef<HTMLInputElement | null>(null)
    const mbAmount = useRef<HTMLInputElement | null>(null)
    const [tokenPaused, setTokenPaused] = useState(false)
    const {mode, menuMode, updateMode, updateMenuMode} = useThemeMode()

    const reload = () => {
        if (tokenContract !== undefined) refreshToken(tokenContract)
    }
    const refreshToken = async function (contract: ITokenContract) {
        if (!currentUser?.crypto_address) return

        setAlertData({...alertData, tokenAction: {show: false, message: '', variant: ''}})
        setLoading({...loading, tokenActionLoading: true})
        try {
            setTokenBalance(ethers.utils.formatEther(await contract.balanceOf(currentUser?.crypto_address)))
            setTotalSupply(ethers.utils.formatEther(await contract.totalSupply()))
            setTokenPaused(await contract.paused())
        }  catch (e: any) {
            if (typeof e === "string") {
                alertData.tokenAction.message = e
            } else if (e instanceof Error) {
                alertData.tokenAction.message = e.message
            } else {
                alertData.tokenAction.message = e.message
            }
            alertData.tokenAction.variant = 'danger'
            alertData.tokenAction.show = true
        }
        setAlertData({...alertData})
        setLoading({...loading, tokenActionLoading: false})
    }

    const addToWallet = async function () {
        setAlertData({...alertData, tokenAction: {show: false, message: '', variant: ''}})
        setLoading({...loading, tokenActionLoading: true})

        try {
        // wasAdded is a boolean. Like any RPC method, an error may be thrown.
            const wasAdded = await window.ethereum.request({
                method: 'wallet_watchAsset',
                params: {
                type: 'ERC20', // Initially only supports ERC20, but eventually more!
                options: {
                        address: props.token.address, // The address that the token is at.
                        symbol: props.token.tokenSymbol, // A ticker symbol or shorthand, up to 5 chars.
                        decimals: 18, // The number of decimals in the token
                        image: 'https://decentralizing.finance/media/logos/user-token.png', // A string url of the token logo
                    },
                },
            });
        } catch (e: any) {
            if (typeof e === "string") {
                alertData.tokenAction.message = e
            } else if (e instanceof Error) {
                alertData.tokenAction.message = e.message
            } else {
                alertData.tokenAction.message = e.message
            }
            alertData.tokenAction.variant = 'danger'
            alertData.tokenAction.show = true
        }
        setAlertData({...alertData})
        setLoading({...loading, tokenActionLoading: false})
    }
    const userTokenMint = async function() {
        if (tokenContract === undefined || currentUser === undefined) return
        if (mbAmount.current === null || mbTargetAddress.current === null) return

        setAlertData({...alertData, tokenAction: {show: false, message: '', variant: ''}})
        setLoading({...loading, tokenActionLoading: true})
        //validate data
        try {            
            const mintAddress:string = mbTargetAddress.current.value
            if (!ethers.utils.isAddress(mintAddress)) {
                alertData.tokenAction.message = 'Invalid address'
                alertData.tokenAction.variant = 'danger'
                alertData.tokenAction.show = true
                setAlertData({...alertData})
                setLoading({...loading, tokenActionLoading: false})
                mbTargetAddress.current.focus()
                return
            }
            if (mbAmount.current.value === '') {
                alertData.tokenAction.message = 'Enter an amount'
                alertData.tokenAction.variant = 'danger'
                alertData.tokenAction.show = true
                setAlertData({...alertData})
                setLoading({...loading, tokenActionLoading: false})
                mbAmount.current.focus()
                return
            }
            const mintAmount:BigNumber = ethers.utils.parseEther(mbAmount.current.value)
            const tx:any = await tokenContract.mint(mintAddress, mintAmount)
            setAlertData({...alertData, tokenAction: {show: true, message: 'Waiting for transaction to confirm', variant: 'info'}})
            const receipt = await tx.wait();
            setTokenBalance(ethers.utils.formatEther(await tokenContract.balanceOf(currentUser?.crypto_address)))
            setTotalSupply(ethers.utils.formatEther(await tokenContract.totalSupply()))
            setAlertData({...alertData, tokenAction: {show: true, message: 'Mint transaction confirmed!', variant: 'success'}})

        } catch (e: any) {
            if (typeof e === "string") {
                alertData.tokenAction.message = e
            } else if (e instanceof Error) {
                alertData.tokenAction.message = e.message
            } else {
                alertData.tokenAction.message = e.message
            }
            alertData.tokenAction.variant = 'danger'
            alertData.tokenAction.show = true
            setAlertData({...alertData})
        }
        
        setLoading({...loading, tokenActionLoading: false})
    }


    const userTokenBurn = async function() {
        if (tokenContract === undefined || currentUser === undefined) return
        if (mbAmount.current === null || mbTargetAddress.current === null) return

        setAlertData({...alertData, tokenAction: {show: false, message: '', variant: ''}})
        setLoading({...loading, tokenActionLoading: true})
        //validate data
        try {            
            const mintAddress:string = mbTargetAddress.current.value
            if (!ethers.utils.isAddress(mintAddress)) {
                alertData.tokenAction.message = 'Invalid address'
                alertData.tokenAction.variant = 'danger'
                alertData.tokenAction.show = true
                setAlertData({...alertData})
                setLoading({...loading, tokenActionLoading: false})
                mbTargetAddress.current.focus()
                return
            }
            if (mbAmount.current.value === '') {
                alertData.tokenAction.message = 'Enter an amount'
                alertData.tokenAction.variant = 'danger'
                alertData.tokenAction.show = true
                setAlertData({...alertData})
                setLoading({...loading, tokenActionLoading: false})
                mbAmount.current.focus()
                return
            }
            const mintAmount:BigNumber = ethers.utils.parseEther(mbAmount.current.value)
            console.log(mintAddress, mintAmount)
            const tx:any = await tokenContract.burn(mintAddress, mintAmount)
            setAlertData({...alertData, tokenAction: {show: true, message: 'Waiting for transaction to confirm', variant: 'info'}})
            const receipt = await tx.wait();
            setTokenBalance(ethers.utils.formatEther(await tokenContract.balanceOf(currentUser?.crypto_address)))
            setTotalSupply(ethers.utils.formatEther(await tokenContract.totalSupply()))
            setAlertData({...alertData, tokenAction: {show: true, message: 'Burn transaction confirmed!', variant: 'success'}})

        } catch (e: any) {
            if (typeof e === "string") {
                alertData.tokenAction.message = e
            } else if (e instanceof Error) {
                alertData.tokenAction.message = e.message
            } else {
                alertData.tokenAction.message = e.message
            }
            alertData.tokenAction.variant = 'danger'
            alertData.tokenAction.show = true
            setAlertData({...alertData})
        }
        
        setLoading({...loading, tokenActionLoading: false})
    }

    //setTokenPaused(await contract.paused())

    const pauseUnpause = async function() {
        if (tokenContract === undefined || currentUser === undefined) return
        
        setAlertData({...alertData, tokenAction: {show: false, message: '', variant: ''}})
        setLoading({...loading, tokenActionLoading: true})
        //validate data
        try {            
            const paused =  await tokenContract.paused()
            const tx:any = paused ? await tokenContract.unpauseToken() : await tokenContract.pauseToken()
            setAlertData({...alertData, tokenAction: {show: true, message: 'Waiting for transaction to confirm', variant: 'info'}})
            const receipt = await tx.wait();
            setTokenPaused(await tokenContract.paused())
            setAlertData({...alertData, tokenAction: {show: true, message: `Success! Token ${paused ? 'unpaused' : 'paused'}.`, variant: 'success'}})
        } catch (e: any) {
            if (typeof e === "string") {
                alertData.tokenAction.message = e
            } else if (e instanceof Error) {
                alertData.tokenAction.message = e.message
            } else {
                alertData.tokenAction.message = e.message
            }
            alertData.tokenAction.variant = 'danger'
            alertData.tokenAction.show = true
            setAlertData({...alertData})
        }
        
        setLoading({...loading, tokenActionLoading: false})
    }

    useEffect(() => {
        (async function() {
            if (!connected) connect()
            const tc:ITokenContract = new ethers.Contract(props.token.address, tokenABI, signer) as ITokenContract
            setTokenContract(tc)            
            refreshToken(tc)
        })()
    }, [connected])

// default return
/*
                borderColor: '#6585a738',
                borderTopWidth: '1px',
                borderStyle: 'groove',
                borderBottomWidth: '1px',
                borderLeftWidth: '2px',
                borderRightWidth: '2px',
                */
return (
    <>
        <div className={'col-xl-3 p-2 ' + (props.token.address === '' ? 'd-none' : '')}>
            <div className={`mb-8 card bg-gray-200 hoverable card-xl-stretch mb-xl-8 card-body card-borders  ${mode === 'dark' ? 'card-gradient' : ''} p-2 pt-4 pb-4`} style={{minHeight: '480px'}}>
                <div className='text-center pb-4 pt-2'>
                    <img src="/media/logos/user-token.png" alt="DFD" style={{borderRadius: '50%', maxHeight: '64px'}}></img>
                </div>
                <p className='fw-bold fs-4 mb-4'>{props.token.tokenName}</p>

                {/********************
                 * 
                 * Tabs panel header
                 * 
                 ********************/}
                <div className='tabs-header-custom'>
                    <ul
                    className='nav nav-stretch nav-line-tabs fw-bold border-transparent flex-nowrap'
                    role='tablist'
                >
                    <li className='nav-item'>
                    <p
                        className={clsx(`nav-link cursor-pointer`, {active: tab === 'Stats'})}
                        onClick={() => setTab('Stats')}
                        role='tab'
                    >
                        Stats
                    </p>
                    </li>

                    <li className='nav-item'>
                    <p
                        className={clsx(`nav-link cursor-pointer`, {active: tab === 'Mint'})}
                        onClick={() => setTab('Mint')}
                        role='tab'
                    >
                        Mint/Burn
                    </p>
                    </li>
                    <li className='nav-item'>
                    <p
                        className={clsx(`nav-link cursor-pointer`, {active: tab === 'Pause'})}
                        onClick={() => setTab('Pause')}
                        role='tab'
                    >
                        { tokenPaused ? 'Unpause' : 'Pause' }
                    </p>
                    </li>
                </ul>
                </div>
                    
                {/**********************
                 * 
                 *  Stats tab panel
                 * 
                 *********************/}
                <div className='tab-content'>
                    <div className={clsx('tab-pane', {active: tab === 'Stats'})}>
                        <div className="mx-1 my-4" style={{borderBottom: '1px dashed rgb(94 94 94 / 33%)'}}></div>
                        <div className={`table-responsive`}>
                            <table className={`table table-striped table-light table-hover p-0 mb-0`}>
                                <tbody>
                                    <tr>
                                        <td>
                                            <p className='mb-0 ps-2 pe-2' style={{textAlign: 'left'}}>Address 
                                                <span style={{float: 'right'}}>
                                                    <a href={`https://goerli.etherscan.io/token/${props.token.address}`} target={`_blank`}>{shortenCryptoAddress(props.token.address)}</a>
                                                </span>
                                            </p>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <p className='mb-0 ps-2 pe-2' style={{textAlign: 'left'}}>Symbol <span style={{float: 'right'}}>{props.token.tokenSymbol}</span></p>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <p className='mb-0 ps-2 pe-2' style={{textAlign: 'left'}}>Initial Supply <span style={{float: 'right'}}>{props.token.initialSupply}</span></p>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <p className='mb-0 ps-2 pe-2' style={{textAlign: 'left'}}>Total Supply <span style={{float: 'right'}}>{totalSupply}</span></p>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <p className='mb-0 ps-2 pe-2' style={{textAlign: 'left'}}>Balance
                                                <span style={{float: 'right'}}>
                                                    <a href={`https://goerli.etherscan.io/token/${props.token.address}?a=${currentUser?.crypto_address}`} target={`_blank`}>{tokenBalance}</a>
                                                </span>
                                            </p>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
                 
                {/**********************
                 * 
                 * Mint tab pane selected
                 * 
                 *********************/}
                <div className='tab-content'>
                    <div className={'' + clsx('tab-pane', {active: tab === 'Mint'})}>
                        <div className="mx-1 my-4" style={{borderBottom: '1px dashed rgb(94 94 94 / 33%)'}}></div>
                        <div className='my-2'>
                            <p className='mb-2'>Address</p>
                            <Form.Control className='mb-4' type="text" placeholder="0x0000000000000000000000000000000000000000" ref={mbTargetAddress} />
                            <p className='mb-2'>Amount</p>
                            <Form.Control className='mb-4' type="number" placeholder="1000000" min="0" ref={mbAmount} />
                            <ButtonGroup className='px-4' style={{width: '100%'}} aria-label="Basic example">
                                <Button variant="success" onClick={userTokenMint}>Mint</Button>
                                <Button variant="danger" onClick={userTokenBurn}>Burn</Button>
                            </ButtonGroup>
                        </div>
                    </div>
                </div>

                 {/**********************
                  * 
                  * Pause tab pane selected
                  * 
                  *********************/}
                <div className='tab-content'>
                    <div className={'' + clsx('tab-pane', {active: tab === 'Pause'})}>
                        <div className="mx-1 my-4" style={{borderBottom: '1px dashed rgb(94 94 94 / 33%)'}}></div>
                        <div className='my-2'>
                            <Button className='px-4' style={{width: '100%'}} variant="primary" onClick={pauseUnpause}>{ tokenPaused ? 'Unpause' : 'Pause' }</Button>
                        </div>
                    </div>
                </div>
                <div className="mx-1 my-4" style={{borderBottom: '1px dashed rgb(94 94 94 / 33%)'}}></div>
                {/*
                <ButtonGroup aria-label="Basic example">
                    <Button variant="secondary">Left</Button>
                    <Button variant="secondary">Middle</Button>
                    <Button variant="secondary">Right</Button>
                </ButtonGroup>
                */}
                { alertData.tokenAction.show === true &&
                            <Alert
                                variant={alertData.tokenAction.variant} 
                                onClose={() => { setAlertData({...alertData, tokenAction: {show: false, message: alertData.tokenAction.message, variant: alertData.tokenAction.variant}})}} 
                                style={{maxWidth: '400px'}}
                                dismissible>
                                {alertData.tokenAction.message}
                            </Alert>
                            }
                <Dropdown className='container-fluid p-0'>
                    <Dropdown.Toggle className='' variant="primary">
                        {props.token.tokenName}
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                        <Dropdown.Item onClick={reload}>Refresh Balances</Dropdown.Item>
                        <Dropdown.Item onClick={addToWallet}>Add to Wallet</Dropdown.Item>
                    </Dropdown.Menu>
                </Dropdown>
            </div>
        </div>
    </>
  )
}

export { UserToken };