import {
    FC,
    useState,
    useEffect,
    createContext,
    useContext,
    useRef,
    Dispatch,
    SetStateAction,
  } from 'react'
  import {  BigNumberish, ethers } from 'ethers'
  //import {LayoutSplashScreen} from '../../../../_metronic/layout/core'
  //import {AuthModel, UserModel} from './_models'
  //import * as authHelper from './AuthHelpers'
  //import {getUserByToken} from './_requests'
  import {WithChildren} from '../../../_metronic/helpers'
  import  { ChainIds, IChainType } from './ChainData/Chains'
  
  /*type AuthContextProps = {
    auth: AuthModel | undefined
    saveAuth: (auth: AuthModel | undefined) => void
    currentUser: UserModel | undefined
    setCurrentUser: Dispatch<SetStateAction<UserModel | undefined>>
    logout: () => void
  }*/
  
  type EthersContextProps = {
    connect: () => void
    isConnected: () => boolean
    connected: boolean | undefined
    provider: ethers.providers.Web3Provider | undefined
    signer: ethers.providers.JsonRpcSigner | undefined
    address: string | undefined
    chainId: string | undefined
    block: string | undefined
    balance: BigNumberish | undefined
    chainData: any //todo
    switchChain: () => void
  }

  const initEthersContextPropsState = {
    connect: () => {},
    isConnected: function() { return false },
    connected: undefined,
    provider: undefined,
    signer: undefined,
    address: undefined,
    chainId: undefined,
    block: undefined,
    balance: undefined,
    chainData: undefined,
    switchChain: () => {},
  }

  /*const initAuthContextPropsState = {
    auth: authHelper.getAuth(),
    saveAuth: () => {},
    currentUser: undefined,
    setCurrentUser: () => {},
    logout: () => {},
  }*/
  /*
    ethereum = window.ethereum
    provider = new ethers.providers.Web3Provider(ethereum)
    await provider.send("eth_requestAccounts", [])
    const signer: ethers.providers.JsonRpcSigner = provider.getSigner()
    const address: string = await signer.getAddress()
*/
  const EthersContext = createContext<EthersContextProps>(initEthersContextPropsState)
  
  /*const useAuth = () => {
    //return useContext(AuthContext)
  }*/
  
  const useEthers = () => {
    return useContext(EthersContext)
  }
  
  const EthersProvider: FC<WithChildren> = ({children}) => {
    //let provider: ethers.providers.Web3Provider | undefined = undefined, setProvider
    const [provider, setProvider] = useState<ethers.providers.Web3Provider>()
    //let signer: ethers.providers.JsonRpcSigner | undefined = undefined, setSigner
    const [signer, setSigner] = useState<ethers.providers.JsonRpcSigner>()
    const [address, setAddress] = useState<string>()
    const [balance, setBalance] = useState<BigNumberish>()
    const [chainId, setChainId] = useState<string>('0x0')
    const [block, setBlock] = useState<string>('0x0')
    const [connected, setConnected] = useState<boolean>(false)
    const [chainData, setChainData] = useState<any>()
    //let address:string='', chainId:string='0x0', block:number=0;

    useEffect(() => { 
      
        return function cleanup() {
  
        }
      }, [])

    const updateBalance = async function() {
      if (provider === undefined) return
      let newBal = await provider.getBalance(address === undefined ? '' : address)
      //console.log('balance update -> ', newBal, balance, balance, balance !== newBal)
      setBalance(newBal)
    }

    const connect = async function(): Promise<void> {
    //const connect = (): void => {
      console.log(`connect hit`)
      if (connected) return
      const connectionInit = async function(): Promise<void> {
        //console.log(ethereum)
        const newProvider: ethers.providers.Web3Provider = new ethers.providers.Web3Provider(window.ethereum)
        setProvider(newProvider)
        await newProvider.send("eth_requestAccounts", [])
        const newSigner:ethers.providers.JsonRpcSigner = newProvider.getSigner();
        setSigner(newSigner)
        const address:string = await newSigner.getAddress()
        setAddress(address)
        const queryChainId: string = String(Number(await newProvider.send("eth_chainId",[])))
        setChainId(queryChainId)
        //console.log(ChainIds[queryChainId as keyof typeof ChainIds])
        //const queryChainData: Object<IChainType> = ChainIds[queryChainId as keyof typeof ChainIds]
        setChainData(ChainIds[queryChainId as keyof typeof ChainIds])
        setBlock(String(await newProvider.getBlockNumber()))
        setConnected(true)
        setBalance(await newProvider.getBalance(address))
        return 
      }

      await connectionInit()

      const accountsChanged = async function (accounts: string[]){
        //console.log('accountsChanged', accounts)
        await connectionInit()
        setConnected(accounts.length > 0)
        if (accounts.length) {
          setAddress(accounts[0])
          //if (signer !== undefined && provider !== undefined) setBalance(await provider.getBalance(accounts[0]))
          //if (provider !== undefined)setTimeout(async function() { setBalance(await provider.getBalance(accounts[0])); }, 2500)
        }
      }
            
      //Watch for when the address changes in Metamask
      window.ethereum.on('accountsChanged', accountsChanged);
      
      //Watch for when user changes chain
      window.ethereum.on('chainChanged', (chainId: string) => { 
        window.location.reload()
        //connectionInit()
      });
        //setChainId(chainId); 
        //setTimeout(async function() { if (address !== undefined) { console.log(address); setBalance(await x.getBalance(await (x.getSigner()).getAddress())) } }, 5000) });              //update when user switches chain id
      //ethereum.on('connect', (connectInfo: any) => { console.log(connectInfo, typeof connectInfo); setChainId(connectInfo.chainId) });   //set initial chain id
      /*interface ConnectInfo {
        chainId: string;
      }
      ethereum.on('connect', (connectInfo: ConnectInfo) => {console.log(connectInfo)});
      window.ethereum.on('connect', (connectInfo: any) => { console.log(connectInfo) });*/

      // Example 4: Log new blocks
      const subscriptionId:any = await window.ethereum.request({method: 'eth_subscribe', params: ['newHeads'],})
      window.ethereum.on('message', async function(message:any) {
        if (message.type === 'eth_subscription') {
          const { data } = message;
          if (data.subscription === subscriptionId) {
            if ('result' in data) {
              const block = data.result;
              setBlock(block.number)
              if (provider !== undefined) {
                updateBalance()
              }
              //if (address !== undefined) setBalance(await x.getBalance(address))
              //setBalance(await x.getBalance(address === undefined ? '' : address))
            } else {
              console.error(`eth_subscription->message: Something went wrong: ${data.result}`);
            }
          }
        }
      });
    }

    
    const isConnected = (): boolean => {
        return window.ethereum.isConnected()
    }

    const switchChain = async function () {
      try {
        await window.ethereum.request({
          method: 'wallet_switchEthereumChain',
          params: [{ chainId:  '0x5'}],
        });
      } catch (switchError: any) {
        // This error code indicates that the chain has not been added to MetaMask.
        if (switchError.code === 4902) {
          try {
            await window.ethereum.request({
              method: 'wallet_addEthereumChain',
              params: [
                {
                  chainId: '0x5',
                  chainName: 'Goerli Testnet',
                  rpcUrls: ['https://goerli.infura.io/v3/'] ,
                },
              ],
            });
          } catch (addError) {
            // handle "add" error
          }
        }
        // handle other "switch" errors
      }
      
      
    }
    
    return (
      <EthersContext.Provider value={{connect, isConnected, connected, provider, signer, address, block, chainId, balance, chainData, switchChain}}>
        {children}
      </EthersContext.Provider>
    )

  }
  

export function shortenCryptoAddress(addr: string): string {
  return addr.substring(0, 6) + '.'.repeat(3) + addr.substring(addr.length - 4)
}

export {EthersProvider, useEthers}
  