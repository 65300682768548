import React, { useCallback }  from "react"
import Select from 'react-select'
import { PageTitle } from "../../../_metronic/layout/core"
import { useAuth } from "../../modules/auth";
import { Container, Row, Col, Button, Form } from "react-bootstrap";
import { Toast, ToastContainer } from "react-bootstrap";
import { useEffect, useRef, useState }  from "react"
import { KTSVG, useDebounce } from "../../../_metronic/helpers";
import { useEthers, shortenCryptoAddress } from "../../modules/ethers/ethersProvider";
import { BigNumber, BytesLike, ethers } from "ethers";
import Dropdown from 'react-bootstrap/Dropdown';
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import { toAbsoluteUrl } from "../../../_metronic/helpers";
import '../../../_metronic/assets/css/cardBorders.css';
import clsx from 'clsx'
import '../../../_metronic/assets/css/tabs.css'
import { dfdTokens } from "../token-factory/TokenFactory";
import { useThemeMode } from "../../../_metronic/partials";

import {  IUniswapV2Factory, IUniswapV2Router02, IUniswapV2Pair  } from "./typechain-types";
import { factoryABI, pairABI, routerABI } from "./abi";
import { getUserTokens } from "../token-factory/TokenFactory";
// 0xE0d6297B8d00890b8430b0aa119a7eaEb71623F5 // goerli factory
// 0xA0EBfdadc4c838E8870d043E30beC7e1bc0ED0C7 // ganache factory

//hardhat
//export const UNISWAP_FACTORY_ADDRESS = '0xC5888275e0a1ca13a26463318105957aa4d1feD7'
//export const UNISWAP_ROUTER_ADDRESS = '0x87B6a0ab90d826189cC004Dc2ff16E2b472309db'

//goerli
export const UNISWAP_FACTORY_ADDRESS = '0x5C69bEe701ef814a2B6a3EDD4B1652CB9cc5aA6f'
export const UNISWAP_ROUTER_ADDRESS = '0x7a250d5630B4cF539739dF2C5dAcb4c659F2488D'

 const customStyles = {
    control: (base: any) => ({
        ...base,
        color: 'black'
      }),
      menu: (base: any) => ({
        ...base,
        color: 'black'
      })
  }
  
const tokenABI = ['function allowance(address owner, address spender) view returns (uint256)',
  'function approve(address spender, uint256 amount) returns (bool)',
  'function balanceOf(address account) view returns (uint256)',]
interface ITokenContract extends ethers.Contract {
    balanceOf: (owner: string) => Promise<BigNumber>,
    approve: (spender: string, amount: BigNumber) => Promise<boolean>,
    allowance: (owner: string, spender: string) => Promise<BigNumber>
    /*totalSupply: () => Promise<BigNumber>,
    faucetMint: (amount: BigNumber) => Promise<void>
    mint: (to: string, amount: BigNumber) => Promise<void>
    paused: () => Promise<boolean>
    pauseToken: () => Promise<void>
    unpauseToken: () => Promise<void>*/
}
interface IPairData {
    isHolding0: boolean;
    hasLiquidity: boolean;
    reserves: [BigNumber, BigNumber, number];
    token0Data: ITokenData;
    token1Data: ITokenData;
    userLPTokens: string;
    userLPAllowance: string;
    totalLPTokens: string;
    shareOfPool: string;
}
interface ITokenData { liqAmount: string; tokenName: string; tokenSymbol: string; initialSupply: string; address: string; maxMint: string; routerAllowance: string; }

const UniswapDex:React.FC = () => {
    const { currentUser } = useAuth()
    const {mode, menuMode, updateMode, updateMenuMode} = useThemeMode()    
    const { connect, isConnected, connected, address, provider, signer, block, chainId, balance, chainData, switchChain } = useEthers()

    const [toastData, setToastData] = useState({show: false, content: <></>, title: '', status: ''})
    const [holdingTokenContract, setHoldingTokenContract] = useState<ITokenContract>()
    const [holdingTokenBalance, setHoldingTokenBalance] = useState<string>('0.0')
    const [holdingTokenAllowance, setHoldingTokenAllowance] = useState<string>('0.0')
    const [desiredTokenContract, setDesiredTokenContract] = useState<ITokenContract>()
    const [desiredTokenBalance, setDesiredTokenBalance] = useState<string>('0.0')
    const [desiredTokenAllowance, setDesiredTokenAllowance] = useState<string>('0.0')

    const [holdingTokenAmount, setHoldingTokenAmount] = useState<string>('')
    const holdingTokenAmountRef = useRef<HTMLInputElement | null>(null) //ref to input field amount

    const holdingToken = useRef(null) //Refs to input field
    const desiredToken = useRef(null) //

    const [holdingTokenAddress, setHoldingTokenAddress] = useState<string>()
    const [desiredTokenAddress, setDesiredTokenAddress] = useState<string>()

    const holdingTokenAmountDebounced = useDebounce(holdingTokenAmount, 500)  //for getting quote

    const [uniswapFactoryContract, setUniswapFactoryContract] = useState<IUniswapV2Factory>()
    const [uniswapPairContract, setUniswapPairContract] = useState<IUniswapV2Pair>()
    const [uniswapRouterContract, setUniswapRouterContract] = useState<IUniswapV2Router02>()
    
    //const [showSwapPairInfo, setShowSwapPairInfo] = useState<boolean>(false)
    const [swapPairExists, setSwapPairExists] = useState<boolean>(false)
    const [currentPairData, setCurrentPairData] = useState<IPairData>()

    const [tab, setTab] = useState<string>('stats')
    const holdingLiquidityAmount = useRef<HTMLInputElement | null>(null) //ref to holding token field amount -> add initial liquidity
    const desiredLiquidityAmount = useRef<HTMLInputElement | null>(null) //ref to desired token field amount -> add initial liquidity
    const removeLPAmount = useRef<HTMLInputElement | null>(null) //ref to holding token field amount -> add initial liquidity

    const [swapDataAmountOut, setSwapDataAmountOut] = useState<BigNumber>()
    /*
    const [options, setOptions] = useState([{
        label: 'DFD Tokens',
        options: dfdTokens.map(token => {
        return { value: token.address, label: `${token.tokenName} (${token.tokenSymbol}) [${shortenCryptoAddress(token.address)}]`}
        })
    }])
    */
/*************************************************************
 *      Swapping Token Options
 *************************************************************/
    const dfdTokensOptions = dfdTokens.map(token => {
        return { value: token.address, label: (
            <>
                {/*
                <div style={{width: '100%', justifyContent: 'center', display: 'flex'}}><img 
                src='/media/logos/favicon.png'
                style={{maxHeight: '32px', borderRadius: '4px' }}
                ></img> <p>{token.tokenName} ({token.tokenSymbol}) [{shortenCryptoAddress(token.address)}]</p></div>
                */} 
                <div style={{textAlign: 'center'}}>
                    <img src='/media/logos/favicon.png'
                    style={{maxHeight: '24px', borderRadius: '4px' }}
                    ></img> <span>{token.tokenName} ({token.tokenSymbol}) [{shortenCryptoAddress(token.address)}]</span>
                </div>
            </>
        ), disabled: false}
    })
    
    const [tokenOptions, setTokenOptions] = useState(dfdTokensOptions)
    const [userTokenOptions, setUserTokenOptions] = useState<{ value: string; label: JSX.Element; disabled: boolean; }[]>()
    const [groupedTokenOptions, setGroupedTokenOptions] = useState<{ label: string; options: { value: string; label: JSX.Element; disabled: boolean; }[]; }[]>()

    const [token2Options, setToken2Options] = useState<{ label: string; options: { value: string; label: JSX.Element; disabled: boolean; }[]; }[]>()

/*************************************************************
 *      useEffects
 *************************************************************/
 useEffect(() => {
    reloadContracts()
},[])

useEffect(() => {
    if (currentUser === undefined) return
    connect()
}, [connected])


//When user changes any of the tokens, we want to refresh that pair info
useEffect(() => {
    //getPairInfo
    getPairInfo()
}, [holdingTokenContract, desiredTokenContract]);
//}, [holdingTokenAddress, desiredTokenAddress, holdingTokenContract, desiredTokenContract]);
//}, [holdingTokenAmountDebounced, holdingTokenAddress, desiredTokenAddress]);

// New uniswapPairContract
useEffect(() => {
    console.log(' // New uniswapPairContract')
    reloadPairData()
}, [uniswapPairContract])

useEffect(() => {
    checkQuote()
}, [uniswapPairContract, holdingTokenAmountDebounced])



/*************************************************************
 *      Component methods
 *************************************************************/
    const reloadContracts = async function(): Promise<void> {
        if (!connected) connect() 
        //setUniswapFactoryContract(new ethers.Contract('0xE0d6297B8d00890b8430b0aa119a7eaEb71623F5', factoryABI , signer) as IUniswapV2Factory) //goerli
        //setUniswapFactoryContract(new ethers.Contract('0xA0EBfdadc4c838E8870d043E30beC7e1bc0ED0C7', factoryABI , signer) as IUniswapV2Factory) //ganache
        const factory: IUniswapV2Factory = new ethers.Contract(UNISWAP_FACTORY_ADDRESS, factoryABI , signer) as IUniswapV2Factory
        setUniswapFactoryContract(factory) //hardhat
        setUniswapRouterContract(new ethers.Contract(UNISWAP_ROUTER_ADDRESS, routerABI, signer) as IUniswapV2Router02)
        reloadTokens()
    }

    const reloadTokens = async function (): Promise<void> {
        if (!connected) connect()
        if (currentUser === undefined) 
            return
        
        const userTokens = await (await getUserTokens(currentUser?.id, currentUser?.api_token)).data.data
        const userTokensMapped = userTokens.map((token: any) => {   
                return { value: token.tokenAddress, label: (
                    <>
                        {/*
                        <div style={{width: '100%', justifyContent: 'center', display: 'flex'}}><img 
                        src='/media/logos/favicon.png'
                        style={{maxHeight: '32px', borderRadius: '4px' }}
                        ></img> <p>{token.tokenName} ({token.tokenSymbol}) [{shortenCryptoAddress(token.address)}]</p></div>
                        */} 
                        <div style={{textAlign: 'center'}}>
                            <img src='/media/logos/user-token.png'
                            style={{maxHeight: '24px', borderRadius: '4px' }}
                            ></img> <span>{token.tokenName} ({token.tokenSymbol}) [{shortenCryptoAddress(token.tokenAddress)}]</span>
                        </div>
                    </>
                ), disabled: false}
        })
        setUserTokenOptions(userTokensMapped)
        setGroupedTokenOptions([
            {
              label: "Site Tokens",
              options: dfdTokensOptions
            },
            {
              label: "User Tokens",
              options: userTokensMapped
            }
            ,
            {
              label: "Imported Tokens",
              options: []
            }
          ])
        console.log(userTokens, dfdTokensOptions, userTokensMapped)
    }

    /**
     * triggered when the user selects a new 'holding' token in the swap
     */
     const holdingTokenChanged = async function (val: string): Promise<void> {
        // We check the 2nd field and make sure they don't match.
        // If match, clear 2nd field.
        if (holdingToken.current === null || desiredToken.current === null || currentUser?.crypto_address === undefined || holdingTokenAmountRef.current === null || 
            userTokenOptions === undefined) return
        let dRef: any = desiredToken.current
        let dRefVals = dRef.getValue()
        if (dRefVals.length && val === dRefVals[0].value) { 
            dRef.clearValue()
            setCurrentPairData(undefined)
            setUniswapPairContract(undefined)
            setDesiredTokenContract(undefined)
        }

        //Now that we have the first token field selected, we map the 2nd one but disable any matching addresses to the first token field.
        const dfdTokenOptionsFiltered:Array<{ value: string; label: JSX.Element; disabled: boolean; }> = []
        tokenOptions.forEach(o => {
            if (o.value === val) return
            dfdTokenOptionsFiltered.push({value: o.value, label: o.label, disabled: o.disabled})
        })
        
        const userTokenOptionsFiltered:Array<{ value: string; label: JSX.Element; disabled: boolean; }> = []
        userTokenOptions.map(o => { 
            if (o.value === val) return
            userTokenOptionsFiltered.push({value: o.value, label: o.label, disabled: o.disabled})
        })
        setToken2Options([{
            label: "Site Tokens",
            options: dfdTokenOptionsFiltered
          },
          {
            label: "User Tokens",
            options: userTokenOptionsFiltered
          }
          ,
          {
            label: "Imported Tokens",
            options: []
          }])
        const token: ITokenContract = new ethers.Contract(val, tokenABI, signer) as ITokenContract
        const tokenBalance:string = ethers.utils.formatEther(await token.balanceOf(currentUser.crypto_address))
        const tokenAllowance:string = ethers.utils.formatEther(await token.allowance(currentUser.crypto_address, UNISWAP_ROUTER_ADDRESS))

        holdingTokenAmountRef.current.value = '0.0'
        setHoldingTokenContract(token)
        setHoldingTokenBalance(tokenBalance)
        setHoldingTokenAllowance(tokenAllowance)
    }

    /**
     * triggered when the user selects a new 'holding' token in the swap
     */
     const desiredTokenChanged = async function (val: string): Promise<void> {
        // We check the 2nd field and make sure they don't match.
        // If match, clear 2nd field.
        if (desiredToken.current === null || currentUser?.crypto_address === undefined) return
        let dRef: any = desiredToken.current
        let dRefVals = dRef.getValue()
        if (dRefVals.length && val === dRefVals[0].value) { 
            dRef.clearValue()
            setCurrentPairData(undefined)
            setUniswapPairContract(undefined)
        }
        
        const token: ITokenContract = new ethers.Contract(val, tokenABI, signer) as ITokenContract
        const tokenBalance:string = ethers.utils.formatEther(await token.balanceOf(currentUser.crypto_address))
        const tokenAllowance:string = ethers.utils.formatEther(await token.allowance(currentUser.crypto_address, UNISWAP_ROUTER_ADDRESS))

        setDesiredTokenContract(token)
        setDesiredTokenBalance(tokenBalance)
        setDesiredTokenAllowance(tokenAllowance)
    }

    /**
     * triggered when the create pair button is pressed, in case a trading pair of two ERC20 tokens doesn't currently exist.
     */
    const createPair = async function(): Promise<void> {
        //console.log('createPair')
        if (holdingToken.current === null || desiredToken.current === null || uniswapFactoryContract === undefined) return
        let hRef:any = holdingToken.current, dRef: any = desiredToken.current
        let dRefVals = dRef.getValue(), hRefVals = hRef.getValue()
        if (!dRefVals.length || !hRefVals.length) return
        try {
            const tx: ethers.ContractTransaction = await uniswapFactoryContract.createPair(dRefVals[0].value, hRefVals[0].value)
            await tx.wait()
            getPairInfo()
            showToast(<>Success! New pair created</>, 'Success: Create Pair', 'success')
        } catch (e) {
            showErr(e, 'Error: Create Pair')
        }
    }

    /**
     * Load/Reload token pair contract 
     */
    const getPairInfo = async function(): Promise<void> {
        //console.log(`getPairInfo -> ${holdingTokenAddress} x ${desiredTokenAddress}` )
        if (!connected) connect()
        setSwapPairExists(false)
        if (holdingTokenAddress === undefined || desiredTokenAddress === undefined || uniswapFactoryContract === undefined) return
        try {
            const pair:string = await uniswapFactoryContract.getPair(holdingTokenAddress, desiredTokenAddress)
            console.log('Uniswap pair -> ', pair)
            const emptyAddr = '0x0000000000000000000000000000000000000000'
            setSwapPairExists(pair !== emptyAddr)
            if (pair === emptyAddr) {
                setUniswapPairContract(undefined)
            } else {
                setUniswapPairContract(new ethers.Contract(pair, pairABI , signer) as IUniswapV2Pair)
            }
        } catch (e) {
            
        }

    }

    /**
     * Triggered when the user hits the bidirectional arrows between the tokens in the swap
     */
    const switchTokens = async function(): Promise<void> {
        if (holdingToken.current === null || desiredToken.current === null) return
        let hRef:any = holdingToken.current, dRef: any = desiredToken.current

        const hRefVal = hRef.getValue()[0]
        hRef.setValue(dRef.getValue()[0])
        dRef.setValue(hRefVal)
    }

    const liquidityHoldingAmountChanged = async function (newValue:string) {
        if (desiredLiquidityAmount.current === null || currentPairData === undefined) return
        if (currentPairData.hasLiquidity) {
            if (newValue === '') {
                desiredLiquidityAmount.current.value = ''
                return
            }
            const BN = ethers.utils.parseEther(newValue)
            //console.log(currentPairData.isHolding0)
            //console.log(currentPairData.reserves[1].div(currentPairData.reserves[0]).toString())
            if (ethers.BigNumber.isBigNumber(BN)) {
                    desiredLiquidityAmount.current.value = ethers.utils.formatEther(currentPairData.isHolding0 ? 
                        BN.mul(currentPairData.reserves[1]).div(currentPairData.reserves[0]) : 
                        BN.mul(currentPairData.reserves[0]).div(currentPairData.reserves[1])
                    )
            }
        }
    }

    const liquidityDesiredAmountChanged = async function (newValue:string) {
        if (holdingLiquidityAmount.current === null || currentPairData === undefined) return
        if (currentPairData.hasLiquidity) {
            if (newValue === '') {
                holdingLiquidityAmount.current.value = ''
                return
            }
            const BN = ethers.utils.parseEther(newValue)
            if (ethers.BigNumber.isBigNumber(BN)) {
                holdingLiquidityAmount.current.value = ethers.utils.formatEther(currentPairData.isHolding0 ? 
                    BN.mul(currentPairData.reserves[0]).div(currentPairData.reserves[1]) : 
                    BN.mul(currentPairData.reserves[1]).div(currentPairData.reserves[0])
                    )
            }
        }
    }
    const addLiquidity = async function(): Promise<void> {
        if (holdingTokenAddress === undefined || 
            desiredTokenAddress === undefined || 
            holdingLiquidityAmount.current === null || 
            desiredLiquidityAmount.current === null || 
            uniswapRouterContract === undefined ||
            currentPairData === undefined || 
            currentUser === undefined)
            return
        try {
            const holdingAmt = ethers.utils.parseEther(holdingLiquidityAmount.current.value)
            const desiredAmt = ethers.utils.parseEther(desiredLiquidityAmount.current.value)
            const holdingSlippage = holdingAmt.sub(holdingAmt.div(100))
            const desiredSlippage = desiredAmt.sub(desiredAmt.div(100))
            const tx: ethers.ContractTransaction = await uniswapRouterContract.addLiquidity(holdingTokenAddress, desiredTokenAddress, holdingAmt, desiredAmt, holdingSlippage, desiredSlippage, currentUser.crypto_address, ethers.utils.parseEther('100000000000000000'))
            await tx.wait()
            reloadPairData()
            showToast(<>Success! Liquidity added.</>, 'Success: Add Liquidity', 'success')
        } catch (e) {
            showErr(e, 'Error: Add Liquidity')
        }
        
    }
    
    const removeLiquidity = async function(): Promise<void> {
        if (holdingTokenAddress === undefined || 
            desiredTokenAddress === undefined || 
            holdingLiquidityAmount.current === null || 
            desiredLiquidityAmount.current === null || 
            removeLPAmount.current === null ||
            uniswapRouterContract === undefined ||
            currentPairData === undefined || 
            currentUser === undefined)
            return
        try { 
            const removeLPAmt = ethers.utils.parseEther(removeLPAmount.current.value)
            //console.log(removeLPAmt.toString())
            const tx: ethers.ContractTransaction = await uniswapRouterContract.removeLiquidity(holdingTokenAddress, desiredTokenAddress, removeLPAmt, 0, 0, currentUser.crypto_address, ethers.utils.parseEther('100000000000000000'))
            await tx.wait()
            showToast(<>Success! Liquidity removed.</>, 'Success: Remove Liquidity', 'success')
            reloadPairData()
        } catch (e) {
            showErr(e, 'Error: Remove Liquidity')
        }

    }
    
    const reloadPairData = async function(): Promise<void> {
        //console.log([uniswapPairContract, holdingTokenAddress, currentUser].indexOf(undefined) !== -1)
        //if ([uniswapPairContract, holdingTokenAddress, currentUser].indexOf(undefined) !== -1)
        //console.log(reloadPairData)
        //setSwapPairExists(false)
        console.log(uniswapPairContract === undefined || 
            holdingTokenAddress === undefined || 
            currentUser === undefined ||
            holdingTokenContract === undefined ||
            desiredTokenContract === undefined 
            ) 
        if (uniswapPairContract === undefined || 
            holdingTokenAddress === undefined || 
            currentUser === undefined ||
            holdingTokenContract === undefined ||
            desiredTokenContract === undefined 
            ) 
            { setCurrentPairData(undefined); return }
        //console.log(pairABI)
        //holdingLiquidityAmount.current.value = ''
        //desiredLiquidityAmount.current.value = ''
        const userTokens = await (await getUserTokens(currentUser.id, currentUser.api_token)).data.data
        const token0Address:string = await uniswapPairContract.token0() 
        const token1Address:string = await uniswapPairContract.token1()
        const reserves:[BigNumber, BigNumber, number] = await uniswapPairContract.getReserves()
        const isHolding0:boolean = holdingTokenAddress === token0Address
        const hasLiquidity:boolean = !reserves[0].isZero() && !reserves[1].isZero() 
        let token0Data:ITokenData = {liqAmount:  '0.0', tokenName: '', tokenSymbol: '', initialSupply: '0.0', address: '', maxMint: '0.0', routerAllowance: '0.0', }
        let token1Data:ITokenData = {liqAmount:  '0.0', tokenName: '', tokenSymbol: '', initialSupply: '0.0', address: '', maxMint: '0.0', routerAllowance: '0.0', }
        let found = 0
        for (let i=0; i<dfdTokens.length; i++) {
            if (dfdTokens[i].address.toLowerCase() === token0Address.toLowerCase()) {
                const routerAllowance = isHolding0 ? await holdingTokenContract.allowance(currentUser.crypto_address, UNISWAP_ROUTER_ADDRESS) : await desiredTokenContract.allowance(currentUser.crypto_address, UNISWAP_ROUTER_ADDRESS)
                token0Data = {...dfdTokens[i], liqAmount: ethers.utils.formatEther(reserves[0]), routerAllowance: routerAllowance.toString()} as ITokenData
                found++
            }
            if (dfdTokens[i].address.toLowerCase() === token1Address.toLowerCase()) {
                const routerAllowance = !isHolding0 ? await holdingTokenContract.allowance(currentUser.crypto_address, UNISWAP_ROUTER_ADDRESS) : await desiredTokenContract.allowance(currentUser.crypto_address, UNISWAP_ROUTER_ADDRESS)
                token1Data = {...dfdTokens[i], liqAmount: ethers.utils.formatEther(reserves[1]), routerAllowance: routerAllowance.toString()} as ITokenData
                found++
            }
            if (found === 2) break
        }
        for (let i=0; i<userTokens.length; i++) {
            if (userTokens[i].tokenAddress.toLowerCase() === token0Address.toLowerCase()) {
                const routerAllowance = isHolding0 ? await holdingTokenContract.allowance(currentUser.crypto_address, UNISWAP_ROUTER_ADDRESS) : await desiredTokenContract.allowance(currentUser.crypto_address, UNISWAP_ROUTER_ADDRESS)
                token0Data = {...userTokens[i], address: userTokens[i].tokenAddress, liqAmount: ethers.utils.formatEther(reserves[0]), routerAllowance: routerAllowance.toString()} as ITokenData
                found++
            }
            if (userTokens[i].tokenAddress.toLowerCase() === token1Address.toLowerCase()) {
                const routerAllowance = !isHolding0 ? await holdingTokenContract.allowance(currentUser.crypto_address, UNISWAP_ROUTER_ADDRESS) : await desiredTokenContract.allowance(currentUser.crypto_address, UNISWAP_ROUTER_ADDRESS)
                token1Data = {...userTokens[i], address: userTokens[i].tokenAddress, liqAmount: ethers.utils.formatEther(reserves[1]), routerAllowance: routerAllowance.toString()} as ITokenData
                found++
            }
            if (found === 2) break
        }
        console.log(found, token0Data, token1Data)
        if (found !== 2 || token0Data === undefined || token1Data === undefined) return

        const userLPTokens = ethers.utils.formatEther(await uniswapPairContract.balanceOf(currentUser?.crypto_address))
        const userLPAllowance = ethers.utils.formatEther(await uniswapPairContract.allowance(currentUser.crypto_address, UNISWAP_ROUTER_ADDRESS))
        const totalLPTokens = ethers.utils.formatEther(await uniswapPairContract.totalSupply())
        const shareOfPool = isNaN(parseFloat(userLPTokens) / parseFloat(totalLPTokens)) ? '0.0' : ((parseFloat(userLPTokens) / parseFloat(totalLPTokens)) * 100).toFixed(6)

        setHoldingTokenBalance(ethers.utils.formatEther(await holdingTokenContract.balanceOf(currentUser?.crypto_address)))
        setDesiredTokenBalance(ethers.utils.formatEther(await desiredTokenContract.balanceOf(currentUser?.crypto_address)))
        const pairData = {
            isHolding0,
            hasLiquidity,
            reserves,
            token0Data, 
            token1Data,
            userLPTokens,
            userLPAllowance,
            totalLPTokens, 
            shareOfPool
        }
        console.log(pairData)
        setCurrentPairData(pairData)   
    }

    /**
     * we check the quote when the pair contract has changed or when the amount to swap has changed
     * @returns 
     */
    const checkQuote = async function(): Promise<void> {

        setSwapDataAmountOut(undefined)

        if (uniswapPairContract === undefined || 
            holdingTokenAddress === undefined || 
            desiredTokenAddress === undefined || 
            currentUser === undefined || 
            currentPairData === undefined || 
            uniswapRouterContract === undefined || 
            holdingTokenAmountRef.current === null
        ) return
        try {
            const swapAmount: BigNumber = ethers.utils.parseEther(holdingTokenAmountRef.current.value)
            const swapAmountOut: BigNumber = (currentPairData.isHolding0) ? await uniswapRouterContract.getAmountOut(swapAmount, currentPairData.reserves[0], currentPairData.reserves[1]) : await uniswapRouterContract.getAmountOut(swapAmount, currentPairData.reserves[1], currentPairData.reserves[0])
            setSwapDataAmountOut(swapAmountOut)
        } catch (e) {
            
        }
        
        //console.log(`You should receive -> ${ethers.utils.formatEther(swapAmountOut)} ${currentPairData.isHolding0 ? currentPairData.token1Data.tokenSymbol :  currentPairData.token0Data.tokenSymbol}`)
        
        
    }

    /**
     * 
     */
    const performSwap = async function(): Promise<void> {
        if (uniswapPairContract === undefined || 
            holdingTokenAddress === undefined || 
            desiredTokenAddress === undefined || 
            currentUser === undefined || 
            currentPairData === undefined || 
            uniswapRouterContract === undefined || 
            holdingTokenAmountRef.current === null || 
            swapDataAmountOut === undefined
        ) return
    try {
        const swapAmount: BigNumber = ethers.utils.parseEther(holdingTokenAmountRef.current.value)
        const tokens = currentPairData.isHolding0 ? [currentPairData.token0Data.address, currentPairData.token1Data.address] : [currentPairData.token1Data.address, currentPairData.token0Data.address]
        const tx: ethers.ContractTransaction = await uniswapRouterContract.swapExactTokensForTokens(swapAmount, 0, tokens, currentUser.crypto_address, ethers.utils.parseEther('1000000000000000000000'))
        const tx2: ethers.ContractReceipt = await tx.wait()


        tx2.events?.forEach(ev => {
            try {
                //console.log({topics: ev.topics, data: ev.data})
                const tmp: ethers.utils.LogDescription = uniswapPairContract.interface.parseLog({topics: ev.topics, data: ev.data})
                if (tmp.name === 'Swap') {
                    //console.log(tmp, tmp.args)
                    if (currentPairData.isHolding0) {
                        //console.log(`Swapped -> ${ethers.utils.formatEther(tmp.args[1])} (${currentPairData.token0Data.tokenSymbol}) for -> ${ethers.utils.formatEther(tmp.args[4])} (${currentPairData.token1Data.tokenSymbol}) `)
                        showToast(
                            <><p>{`Successfully swapped ${ethers.utils.formatEther(tmp.args[1])} (${currentPairData.token0Data.tokenSymbol}) for ${ethers.utils.formatEther(tmp.args[4])} (${currentPairData.token1Data.tokenSymbol}) `}</p>
                                <span><a href={`https://goerli.etherscan.io/tx/${tx.hash}`} target='_blank'>View TX</a></span>
                            </>
                        , "Swap Successful", 'success')
                    } else {
                        //console.log(`Swapped -> ${ethers.utils.formatEther(tmp.args[2])} (${currentPairData.token1Data.tokenSymbol}) for -> ${ethers.utils.formatEther(tmp.args[3])} (${currentPairData.token0Data.tokenSymbol}) `)
                        showToast(
                            <>
                                <p>{`Successfully swapped ${ethers.utils.formatEther(tmp.args[2])} (${currentPairData.token1Data.tokenSymbol}) for ${ethers.utils.formatEther(tmp.args[3])} (${currentPairData.token0Data.tokenSymbol}) `}</p>
                                <span><a href={`https://goerli.etherscan.io/tx/${tx.hash}`} target='_blank'>View TX</a></span>
                            </>
                        , "Swap Successful", 'success')
                    }

                }
                
                //console.log(uniswapPairContract.interface.decodeEventLog('Swap', ev.data, ev.topics))
                //console.log(uniswapPairContract.interface.events)
                //console.log(uniswapPairContract.interface.getEvent('Swap'))
            } catch (e: any) {
                var msg = ''
                if (typeof e === "string") {
                    msg = e
                } else if (e instanceof Error) {
                    msg = e.message
                } else {
                    msg = e.message
                }
                showErr(e, 'Swap Error')
            }

            //console.log(uniswapRouterContract.interface.parseLog({topics: ev.topics, data: ev.data}))
        })
    } catch (e: any) {
        showErr(e, 'Swap Error')
    }
        //const decoded = ethers.utils.defaultAbiCoder.decode(['uint[]'], '0x00000000000000000000000000000000000000000000029d394a5d63054400000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000008a59b054c32a1b3')
        /*if (tx2 !== undefined && tx2.events !== undefined) {
            const decoded = ethers.utils.defaultAbiCoder.decode(['address','uint','uint','uint','uint','address'],tx2.events[tx2.events.length-1].data)
            console.log(decoded)
        }*/
        
        
        //console.log(decoded)
        //let fragment:ethers.utils.Fragment
        //uniswapRouterContract.interface.fragments.forEach(frag => {
        //    if (frag.name === 'swapExactTokensForTokens') fragment = frag
        //})
        //uniswapRouterContract.interface.decodeFunctionResult(tx.data)
        //console.log(tx, tx2)
            
        //console.log(tx2.logs)
        //console.log(uniswapRouterContract, uniswapRouterContract.filters)
        //const data: any = uniswapRouterContract.interface.decodeFunctionData('swapExactTokensForTokens(uint,uint,address[],address,uint) returns (uint[])', tx.data)
        //console.log(routerABI)
        //console.log(uniswapRouterContract.interface.getEvent('Swap'))
        //console.log(uniswapPairContract.interface.getEvent('Swap'))
        
        //console.log(data)
        //console.log(data[0].toString())

            //uniswapRouterContract.interface.decodeFunctionResult()
        //console.log(uniswapRouterContract.interface.parseTransaction({data: tx.data, value: tx.value}))
        //console.log(uniswapRouterContract.interface.decodeFunctionData(fragment, tx.data))
        
        await reloadPairData()
        await checkQuote()
    }

    const showToast = async function (content: JSX.Element, title: string, status: string) {
         setToastData({content, title, status, show: true})
    }

    const showErr = async function(e: any, title: string) {
        var msg = ''
        if (typeof e === "string") {
            msg = e
        } else if (e instanceof Error) {
            msg = e.message
        } else {
            msg = e.message
        }
        showToast(<>{msg}</>, title, 'danger')
    }
/**
 * Begin TSX/component
 */
return (
    <>
        <PageTitle>Uniswap DEX</PageTitle>
        <Container fluid>
            <Row>
                <Col className='col-xl-4 offset-xl-4 text-center'>
                    <div className={`card  bg-gray-200 p-8 hoverable card-lg-stretch mb-4 card-body card-borders ${mode === 'dark' ? 'card-gradient' : ''}`}>
                        {/*
                        <div className="m-1" style={{borderBottom: '1px dashed rgb(94 94 94 / 33%)'}}></div>  
                        <div className='pb-5' style={{maxWidth:'400px', minWidth: '300px', marginRight: 'auto', marginLeft: 'auto'}}>*/}
                        <div className='p-3 pt-0' style={{display: 'flex', justifyContent: 'space-between'}}>
                            {/*<span style={{cursor: 'pointer'}}>Allowance<br/>{holdingTokenAllowance}</span>*/}
                            <span></span>
                            <span style={{cursor: 'pointer'}} onClick={() => {
                                if (holdingTokenAmountRef.current !== null) holdingTokenAmountRef.current.value = holdingTokenBalance
                                checkQuote()
                            }}>Balance {parseFloat(holdingTokenBalance).toFixed(6)}</span>
                        </div>
                        <div className='mb-4' style={{}}>
                            <Select 
                                options={groupedTokenOptions} 
                                styles={customStyles}
                                theme={(theme) => ({
                                    ...theme,
                                    colors: {
                                        ...theme.colors,
                                        primary25: '#009ef74d',
                                        primary: '#009ef7',
                                    },
                                })}
                                ref={holdingToken}
                                onChange={
                                    newValue => { 
                                        if (newValue === null) return
                                        const x:any = newValue
                                        console.log(x.value)
                                        setHoldingTokenAddress(x.value)
                                        holdingTokenChanged(x.value); 
                                        /*
                                        if (newValue === null || holdingToken.current === null) return; 
                                        //const hRef:any = holdingToken.current
                                        //hRef.setValue(newValue)
                                        holdingTokenChanged(newValue.value); 
                                        setTimeout(getPairInfo, 250)
                                        */
                                    }
                                }
                            ></Select>
                        </div>
                        <Form.Control className='mb-4' type="number" placeholder="Amount" 
                        max={holdingTokenBalance} min="0" 
                        onChange={e => setHoldingTokenAmount(e.target.value)} ref={holdingTokenAmountRef} style={{backgroundColor: 'white', color: 'black', textAlign: "center"}}/>
                        <div className={`mb-4`}>
                            <KTSVG path='/media/icons/duotune/arrows/arr032.svg' className={`svg-icon-primary svg-icon-2x ms-n1`} style={{cursor: 'pointer'}} onClick={switchTokens}/>
                        </div>
                        <div className='mb-4' style={{}}>
                            <Select 
                                options={token2Options} 
                                styles={customStyles}
                                theme={(theme) => ({
                                    ...theme,
                                    colors: {
                                        ...theme.colors,
                                        primary25: '#009ef74d',
                                        primary: '#009ef7',
                                    },
                                })}
                                ref={desiredToken}
                                onChange={newValue => {
                                    if (newValue === null) return
                                    const tmp: any = newValue
                                    setDesiredTokenAddress(tmp.value)
                                    desiredTokenChanged(tmp.value)
                                 }}
                            ></Select>
                            { /* isOptionDisabled={(option) => option.disabled} */ }
                            {/*onChange={newValue => { getPairInfo() } }*/}
                        </div>

                        {
                            holdingTokenAllowance && currentPairData && holdingTokenContract !== undefined && holdingTokenAllowance === '0.0' &&
                            currentUser &&
                            <Button className='mb-4' style={{width: '100%'}} variant="primary" onClick={() => { 
                                (async function() {
                                try {
                                    const tx: any= await holdingTokenContract.approve(UNISWAP_ROUTER_ADDRESS, ethers.constants.MaxUint256)
                                    await tx.wait()
                                    setHoldingTokenAllowance(ethers.utils.formatEther(await holdingTokenContract.allowance(currentUser?.crypto_address, UNISWAP_ROUTER_ADDRESS)))
                                    showToast(<>Success! Token approved.</>, 'Success: Approve Tokens', 'success')
                                } catch (e) {
                                    showErr(e, 'Error: Approve Tokens')
                                }
                                })()
                             }}>Approve {
                                currentPairData.isHolding0 ? currentPairData.token0Data.tokenSymbol : currentPairData.token1Data.tokenSymbol
                            }</Button>
                        }

                    {
                        swapDataAmountOut ? 
                        <>
                        <Button className='mb-4' style={{width: '100%'}} variant="primary" onClick={performSwap}>Swap Tokens</Button>
                        <span style={{cursor: 'pointer'}} onClick={checkQuote}>You will receive {swapDataAmountOut !== undefined && parseFloat(ethers.utils.formatEther(swapDataAmountOut)).toFixed(6)} {currentPairData !== undefined && currentPairData.isHolding0 ? currentPairData.token1Data.tokenSymbol : currentPairData !== undefined && currentPairData.token0Data.tokenSymbol}</span>
                        {/*<div className={`table-responsive`}>
                            <table className={`table table-striped table-light table-hover p-0 mb-0`}>
                                <tbody>
                                    <tr>
                                        <th className={`p-2 rounded-2`} colSpan={2}>You will receive</th>
                                    </tr>
                                    <tr>
                                        <td>{swapDataAmountOut !== undefined && ethers.utils.formatEther(swapDataAmountOut)} {currentPairData !== undefined && currentPairData.isHolding0 ? currentPairData.token1Data.tokenSymbol : currentPairData !== undefined && currentPairData.token0Data.tokenSymbol}  </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>*/}
                        </> : 
                        holdingTokenContract && desiredTokenContract && !swapPairExists && <Button className='' style={{width: '100%'}} variant="primary" onClick={createPair}>Create Pair</Button>
                    }
                    </div>
                </Col>
            </Row>


        {   
        /***********************************
         *      Begin Liquidity card
         ***********************************/
        currentPairData && 
            <Row className='mt-xl-4'>
                <Col className='col-xl-4 offset-xl-4 text-center'>
                    <div className={`card  bg-gray-200 p-8 hoverable card-lg-stretch mb-xl-8 card-body card-borders ${mode === 'dark' ? 'card-gradient' : ''}`}>
                        {/********************
                         * 
                         * Tabs panel header
                         * 
                         ********************/}
                        <div className='tabs-header-custom'>
                            <ul
                            className='nav nav-stretch nav-line-tabs fw-bold border-transparent flex-nowrap'
                            role='tablist'
                        >
                            <li className='nav-item'>
                            <p
                                className={clsx(`nav-link cursor-pointer`, {active: tab === 'stats'})}
                                onClick={() => setTab('stats')}
                                role='tab'
                            >
                                Liquidity
                            </p>
                            </li>

                            <li className='nav-item'>
                            <p
                                className={clsx(`nav-link cursor-pointer`, {active: tab === 'add'})}
                                onClick={() => setTab('add')}
                                role='tab'
                            >
                                Add
                            </p>
                            </li>
                            <li className='nav-item'>
                            <p
                                className={clsx(`nav-link cursor-pointer`, {active: tab === 'remove'})}
                                onClick={() => setTab('remove')}
                                role='tab'
                            >
                                Remove
                            </p>
                            </li>
                        </ul>
                        </div>
                        { currentPairData && 
                        <div className='pb-5'>
                            <div className="mx-1 my-4" style={{borderBottom: '1px dashed rgb(94 94 94 / 33%)'}}></div>
                            {/**
                             * Begin liquidity stats
                             */}
                            <div className='tab-content'>
                                <div className={clsx('tab-pane', {active: tab === 'stats'})}>
                                    
                                    <div className={`table-responsive`}>
                                        <table className={`table table-striped table-light table-hover p-0 mb-0`}>
                                            <tbody>
                                                <tr>
                                                    <th className={`p-2 rounded-2`} colSpan={2}>Liquidity for {uniswapPairContract && shortenCryptoAddress(uniswapPairContract.address)}</th>
                                                </tr>
                                                <tr>
                                                    <td className={`p-1 rounded-1`} >
                                                        <p className='mb-0 ps-2 pe-2' style={{textAlign: 'left'}}>{currentPairData && `${currentPairData.token0Data.tokenSymbol}/${currentPairData.token1Data.tokenSymbol}`} 
                                                            <span style={{float: 'right'}}>
                                                                {currentPairData && 
                                                                    (currentPairData.token0Data.liqAmount !== '0.0' && currentPairData.token1Data.liqAmount !== '0.0') ? 
                                                                        //ethers.BigNumber.from(currentPairData.token0Data.liqAmount).div(ethers.BigNumber.from(currentPairData.token1Data.liqAmount)).toString() :
                                                                        //ethers.utils.parseEther(currentPairData.token0Data.liqAmount).div(ethers.utils.parseEther(currentPairData.token1Data.liqAmount)).toString() :
                                                                        (parseFloat(currentPairData.token0Data.liqAmount) / parseFloat(currentPairData.token1Data.liqAmount)).toFixed(6) : 
                                                                        '0.00'
                                                                }
                                                            </span>
                                                        </p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td className={`p-1 rounded-1`} >
                                                        <p className='mb-0 ps-2 pe-2' style={{textAlign: 'left'}}>{currentPairData && `${currentPairData.token1Data.tokenSymbol}/${currentPairData.token0Data.tokenSymbol}`} 
                                                            <span style={{float: 'right'}}>
                                                                {currentPairData && 
                                                                    (currentPairData.token0Data.liqAmount !== '0.0' && currentPairData.token1Data.liqAmount !== '0.0') ? 
                                                                        //ethers.BigNumber.from(currentPairData.token1Data.liqAmount).div(ethers.BigNumber.from(currentPairData.token0Data.liqAmount)).toString() :
                                                                        //ethers.utils.parseEther(currentPairData.token1Data.liqAmount).div(ethers.utils.parseEther(currentPairData.token0Data.liqAmount)).toString() :
                                                                        (parseFloat(currentPairData.token1Data.liqAmount) / parseFloat(currentPairData.token0Data.liqAmount)).toFixed(6) : 
                                                                        '0.00'
                                                                }
                                                            </span>
                                                        </p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td className={`p-1 rounded-1`} >
                                                        <p className='mb-0 ps-2 pe-2' style={{textAlign: 'left'}}>{
                                                            //currentPairData && `${currentPairData.token0Data.tokenName} (${currentPairData.token0Data.tokenSymbol})`
                                                            currentPairData && currentPairData.token0Data && `${currentPairData.token0Data.tokenSymbol} Reserves`
                                                        } 
                                                            <span style={{float: 'right'}}>
                                                                {currentPairData &&  currentPairData.token0Data && parseFloat(currentPairData.token0Data.liqAmount).toFixed(2)}
                                                            </span>
                                                        </p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td className={`p-1 rounded-1`} >
                                                        <p className='mb-0 ps-2 pe-2' style={{textAlign: 'left'}}>
                                                        {
                                                            //currentPairData && `${currentPairData.token0Data.tokenName} (${currentPairData.token0Data.tokenSymbol})`
                                                            currentPairData && `${currentPairData.token1Data.tokenSymbol} Reserves`
                                                        } 
                                                            <span style={{float: 'right'}}>
                                                                {currentPairData && parseFloat(currentPairData.token1Data.liqAmount).toFixed(2)}
                                                            </span>
                                                        </p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td className={`p-1 rounded-1`} >
                                                        <p className='mb-0 ps-2 pe-2' style={{textAlign: 'left'}}>{`Total LP Tokens`} 
                                                            <span style={{float: 'right'}}>
                                                                {currentPairData && 
                                                                    parseFloat(currentPairData.totalLPTokens).toFixed(2)
                                                                }
                                                            </span>
                                                        </p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td className={`p-1 rounded-1`} >
                                                        <p className='mb-0 ps-2 pe-2' style={{textAlign: 'left'}}>{`Your LP Tokens`} 
                                                            <span style={{float: 'right'}}>
                                                                {currentPairData && 
                                                                    parseFloat(currentPairData.userLPTokens).toFixed(2)
                                                                }
                                                            </span>
                                                        </p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td className={`p-1 rounded-1`} >
                                                        <p className='mb-0 ps-2 pe-2' style={{textAlign: 'left'}}>{`Share of pool`} 
                                                            <span style={{float: 'right'}}> %
                                                            {currentPairData && 
                                                                    parseFloat(currentPairData.shareOfPool).toFixed(2)
                                                                } 
                                                            </span>
                                                        </p>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                            {/**
                             * End liquidity stats
                             */}

                             {/**
                              * Begin add liquidity
                              */}
                            <div className='tab-content'>
                                <div className={clsx('tab-pane', {active: tab === 'add'})}>
                                    <div className='p-3 pt-0' style={{display: 'flex', justifyContent: 'space-between'}}>
                                        {/*<span style={{cursor: 'pointer'}}>Allowance<br/>{holdingTokenAllowance}</span>*/}
                                        <span>{
                                            currentPairData.isHolding0 ? `${currentPairData.token0Data.tokenName} (${currentPairData.token0Data.tokenSymbol})` :  `${currentPairData.token1Data.tokenName} (${currentPairData.token1Data.tokenSymbol})`
                                        }</span>
                                        <span style={{cursor: 'pointer'}} onClick={() => {
                                            if (holdingLiquidityAmount.current !== null) {
                                                holdingLiquidityAmount.current.value = holdingTokenBalance
                                                liquidityHoldingAmountChanged(holdingTokenBalance)
                                            }
                                        }}>Balance {parseFloat(holdingTokenBalance).toFixed(6)}</span>
                                    </div>
                                    {/*<span>
                                        currentPairData && 
                                        currentPairData.isHolding0 ? `${currentPairData.token0Data.tokenName} (${currentPairData.token0Data.tokenSymbol})` :  `${currentPairData.token1Data.tokenName} (${currentPairData.token1Data.tokenSymbol})`
                                    </span>*/}
                                    <Form.Control className='mb-4' type="number" placeholder={`${currentPairData.isHolding0 ? currentPairData.token0Data.tokenSymbol : currentPairData.token1Data.tokenSymbol } Amount`} max={holdingTokenBalance} min="0" onChange={(e) => {liquidityHoldingAmountChanged(e.target.value)}} ref={holdingLiquidityAmount} style={{backgroundColor: 'white', color: 'black', textAlign: "center"}}/>

                                    <div className='p-3 pt-0' style={{display: 'flex', justifyContent: 'space-between'}}>
                                        {/*<span style={{cursor: 'pointer'}}>Allowance<br/>{holdingTokenAllowance}</span>*/}
                                        <span>{ !currentPairData.isHolding0 ? `${currentPairData.token0Data.tokenName} (${currentPairData.token0Data.tokenSymbol})` :  `${currentPairData.token1Data.tokenName} (${currentPairData.token1Data.tokenSymbol})` }</span>
                                        <span style={{cursor: 'pointer'}} onClick={() => { if (desiredLiquidityAmount.current !== null) { desiredLiquidityAmount.current.value = desiredTokenBalance; liquidityDesiredAmountChanged(desiredTokenBalance)} }}>Balance {parseFloat(desiredTokenBalance).toFixed(6)}</span>
                                    </div>
                                    <Form.Control className='mb-4' type="number" placeholder={`${!currentPairData.isHolding0 ? currentPairData.token0Data.tokenSymbol : currentPairData.token1Data.tokenSymbol } Amount`} max={holdingTokenBalance} min="0" onChange={(e) => {liquidityDesiredAmountChanged(e.target.value)}} ref={desiredLiquidityAmount} style={{backgroundColor: 'white', color: 'black', textAlign: "center"}}/>

                                {
                                    //desiredTokenAllowance && holdingTokenAllowance && desiredTokenAllowance !== '0.0'  && holdingTokenAllowance !== '0.0' ?  
                                    
                                    currentPairData.hasLiquidity ? 
                                    <Button className='' style={{width: '100%'}} variant="primary" onClick={addLiquidity}>Add Liquidity</Button> : 
                                    <Button className='' style={{width: '100%'}} variant="primary" onClick={addLiquidity}>Set Initial Liquidity</Button> 
                                }
                                </div>
                            </div>
                            {/**
                             * End add liquidity
                             */}

                            {/**
                             * Begin remove liquidity
                             */}
                            <div className='tab-content'>
                                <div className={clsx('tab-pane', {active: tab === 'remove'})}>
                                <div className='p-3 pt-0' style={{display: 'flex', justifyContent: 'space-between'}}>
                                        {/*<span style={{cursor: 'pointer'}}>Allowance<br/>{holdingTokenAllowance}</span>*/}
                                        <span>LP Tokens  {
                                            currentPairData.isHolding0 ? `(${currentPairData.token0Data.tokenSymbol})` :  `(${currentPairData.token1Data.tokenSymbol})`
                                        }</span>
                                        <span style={{cursor: 'pointer'}} onClick={() => {
                                            if (removeLPAmount.current !== null) {
                                                removeLPAmount.current.value = currentPairData.userLPTokens
                                                //liquidityHoldingAmountChanged(holdingTokenBalance)
                                            }
                                        }}>Balance {parseFloat(currentPairData.userLPTokens).toFixed(6)}</span>
                                    </div>
                                    <Form.Control className='mb-4' type="number" placeholder={`LP Tokens`} max={currentPairData.userLPTokens} min="0" ref={removeLPAmount} style={{backgroundColor: 'white', color: 'black', textAlign: "center"}}/>
                                    {
                                       uniswapPairContract !== undefined && (currentPairData.userLPAllowance === '0.0' ? 
                                        <Button className='' style={{width: '100%'}} variant="primary" onClick={() => {
                                            (async function() {
                                                try {
                                                    const tx: ethers.ContractTransaction = await uniswapPairContract.approve(UNISWAP_ROUTER_ADDRESS, ethers.constants.MaxUint256)
                                                    await tx.wait()
                                                    reloadPairData()
                                                    showToast(<>Success! Pair contract LP tokens approved.</>, 'Success: Approve LP Tokens', 'success')
                                                } catch (e) {
                                                    showErr(e, 'Error: Approve LP Tokens')
                                                }
                                            })()
                                        }}>Approve</Button> :
                                        <Button className='' style={{width: '100%'}} variant="primary" onClick={removeLiquidity}>Remove Liquidity</Button>)
                                    } 
                                    
                                </div>
                            </div>
                            {/**
                             * End remove liquidity
                             */}
                            <div className="mt-4" style={{borderBottom: '1px dashed rgb(94 94 94 / 33%)'}}></div>
                        {
                        //Approve 1st token for liquidity
                        holdingTokenAllowance && currentPairData && holdingTokenContract !== undefined && holdingTokenAllowance === '0.0' &&
                        currentUser &&
                            <Button className='mb-4' style={{width: '100%'}} variant="primary" onClick={() => { 
                                (async function() {
                                try {
                                    const tx: any = await holdingTokenContract.approve(UNISWAP_ROUTER_ADDRESS, ethers.constants.MaxUint256)
                                    await tx.wait()
                                    setHoldingTokenAllowance(ethers.utils.formatEther(await holdingTokenContract.allowance(currentUser?.crypto_address, UNISWAP_ROUTER_ADDRESS)))
                                    showToast(<>Success! Token approved.</>, 'Success: Approve Tokens', 'success')
                                } catch (e) {
                                    showErr(e, 'Error: Approve Tokens')
                                }
                                })()
                             }}>Approve {
                                currentPairData.isHolding0 ? currentPairData.token0Data.tokenSymbol : currentPairData.token1Data.tokenSymbol
                            }</Button>
                        }

                        {
                        //Approve 2nd token for liquidity
                        desiredTokenAllowance && currentPairData && desiredTokenContract !== undefined && desiredTokenAllowance === '0.0' &&
                        currentUser &&
                            <Button className='mb-4' style={{width: '100%'}} variant="primary" onClick={() => { 
                                (async function() {
                                try {
                                    const tx:any = await desiredTokenContract.approve(UNISWAP_ROUTER_ADDRESS, ethers.constants.MaxUint256)
                                    await tx.wait()
                                    setDesiredTokenAllowance(ethers.utils.formatEther(await desiredTokenContract.allowance(currentUser?.crypto_address, UNISWAP_ROUTER_ADDRESS)))
                                    showToast(<>Success! Token approved.</>, 'Success: Approve Tokens', 'success')
                                } catch (e) {
                                    showErr(e, 'Error: Approve Tokens')
                                }
                                })()
                             }}>Approve {
                                !currentPairData.isHolding0 ? currentPairData.token0Data.tokenSymbol : currentPairData.token1Data.tokenSymbol
                            }</Button>
                        }
                        </div>
                    }
                    </div>
                </Col>
            </Row>
        }
        { toastData.show && 
                <ToastContainer className="p-3" position={'top-end'}>
                    <Toast className="m-1" bg={toastData.status} key={toastData.status} onClose={() => {setToastData({...toastData, show: false})}}>
                        <Toast.Header>
                            <img
                            src="holder.js/20x20?text=%20"
                            className="rounded me-2"
                            alt=""
                            />
                            <strong className="me-auto">{toastData.title}</strong>
                            <small></small>
                        </Toast.Header>
                        <Toast.Body className={'text-white'}>
                            { toastData.content }
                        </Toast.Body>
                    </Toast>
                </ToastContainer>

        }  
        </Container>

    </>
  )
}

export { UniswapDex };