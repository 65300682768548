import React  from "react"
import { PageTitle } from "../../../_metronic/layout/core"
import { useAuth } from "../../modules/auth";
import { useEthers } from "../../modules/ethers/ethersProvider";
import { toAbsoluteUrl } from "../../../_metronic/helpers";
const PostPage:React.FC = () => {
    const { currentUser } = useAuth()
    const ethereum  = useEthers()

    React.useEffect(() => {
        
    }, [])

    const pageTitle = `Post`

return (
    <>
        <PageTitle>{pageTitle}</PageTitle>
        <div className="card">
            {/*<!--begin::Body-*/}
            <div className="card-body p-lg-15 pb-lg-0">
                {/*<!--begin::Layout-*/}
                <div className="d-flex flex-column flex-xl-row">
                    {/*<!--begin::Content-*/}
                    <div className="flex-lg-row-fluid me-xl-15">
                        {/*<!--begin::Post content-*/}
                        <div className="mb-17">
                            {/*<!--begin::Wrapper-*/}
                            <div className="mb-8">
                                {/*<!--begin::Info-*/}
                                <div className="d-flex flex-wrap mb-6">
                                    {/*<!--begin::Item-*/}
                                    <div className="me-9 my-1">
                                        {/*<!--begin::Icon-*/}
                                        {/*<!--begin::Svg Icon | path: icons/duotune/general/gen025.svg-*/}
                                        <span className="svg-icon svg-icon-primary svg-icon-2 me-1">
                                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <rect x="2" y="2" width="9" height="9" rx="2" fill="currentColor"></rect>
                                                <rect opacity="0.3" x="13" y="2" width="9" height="9" rx="2" fill="currentColor"></rect>
                                                <rect opacity="0.3" x="13" y="13" width="9" height="9" rx="2" fill="currentColor"></rect>
                                                <rect opacity="0.3" x="2" y="13" width="9" height="9" rx="2" fill="currentColor"></rect>
                                            </svg>
                                        </span>
                                        {/*<!--end::Svg Icon-*/}
                                        {/*<!--end::Icon-*/}
                                        {/*<!--begin::Label-*/}
                                        <span className="fw-bold text-gray-400">06 April 2021</span>
                                        {/*<!--end::Label-*/}
                                    </div>
                                    {/*<!--end::Item-*/}
                                    {/*<!--begin::Item-*/}
                                    <div className="me-9 my-1">
                                        {/*<!--begin::Icon-*/}
                                        {/*<!--SVG file not found: icons/duotune/finance/fin006.svgFolder.svg-*/}
                                        {/*<!--end::Icon-*/}
                                        {/*<!--begin::Label-*/}
                                        <span className="fw-bold text-gray-400">Announcements</span>
                                        {/*<!--begin::Label-*/}
                                    </div>
                                    {/*<!--end::Item-*/}
                                    {/*<!--begin::Item-*/}
                                    <div className="my-1">
                                        {/*<!--begin::Icon-*/}
                                        {/*<!--begin::Svg Icon | path: icons/duotune/communication/com003.svg-*/}
                                        <span className="svg-icon svg-icon-primary svg-icon-2 me-1">
                                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path opacity="0.3" d="M2 4V16C2 16.6 2.4 17 3 17H13L16.6 20.6C17.1 21.1 18 20.8 18 20V17H21C21.6 17 22 16.6 22 16V4C22 3.4 21.6 3 21 3H3C2.4 3 2 3.4 2 4Z" fill="currentColor"></path>
                                                <path d="M18 9H6C5.4 9 5 8.6 5 8C5 7.4 5.4 7 6 7H18C18.6 7 19 7.4 19 8C19 8.6 18.6 9 18 9ZM16 12C16 11.4 15.6 11 15 11H6C5.4 11 5 11.4 5 12C5 12.6 5.4 13 6 13H15C15.6 13 16 12.6 16 12Z" fill="currentColor"></path>
                                            </svg>
                                        </span>
                                        {/*<!--end::Svg Icon-*/}
                                        {/*<!--end::Icon-*/}
                                        {/*<!--begin::Label-*/}
                                        <span className="fw-bold text-gray-400">24 Comments</span>
                                        {/*<!--end::Label-*/}
                                    </div>
                                    {/*<!--end::Item-*/}
                                </div>
                                {/*<!--end::Info-*/}
                                {/*<!--begin::Title-*/}
                                <a href="#" className="text-dark text-hover-primary fs-2 fw-bold">Admin Panel - How To Get Started Tutorial. Create a customizable SaaS Based applications and solutions 
                                <span className="fw-bold text-muted fs-5 ps-1">5 mins read</span></a>
                                {/*<!--end::Title-*/}
                                {/*<!--begin::Container-*/}
                                <div className="overlay mt-8">
                                    {/*<!--begin::Image-*/}
                                    <div className="bgi-no-repeat bgi-position-center bgi-size-cover card-rounded min-h-350px" style={{backgroundImage:toAbsoluteUrl('/media/stock/1600x800/img-1.jpg')}}></div>
                                    {/*<!--end::Image-*/}
                                    {/*<!--begin::Links-*/}
                                    <div className="overlay-layer card-rounded bg-dark bg-opacity-25">
                                        <a href="/../demo8/pages/about.html" className="btn btn-primary">About Us</a>
                                        <a href="/../demo8/pages/careers/apply.html" className="btn btn-light-primary ms-3">Join Us</a>
                                    </div>
                                    {/*<!--end::Links-*/}
                                </div>
                                {/*<!--end::Container-*/}
                            </div>
                            {/*<!--end::Wrapper-*/}
                            {/*<!--begin::Description-*/}
                            <div className="fs-5 fw-semibold text-gray-600">
                                {/*<!--begin::Text-*/}
                                <p className="mb-8">First, a disclaimer – the entire process of writing a blog post often takes more than a couple of hours, even if you can type eighty words per minute and your writing skills are sharp. From the seed of the idea to finally hitting “Publish,” you might spend several days or maybe even a week “writing” a blog post, but it’s important to spend those vital hours planning your post and even thinking about 
                                <a href="/../demo8/pages/blog/post.html" className="link-primary pe-1">Your Post</a>(yes, thinking counts as working if you’re a blogger) before you actually write it.</p>
                                {/*<!--end::Text-*/}
                                {/*<!--begin::Text-*/}
                                <p className="mb-8">There’s an old maxim that states, 
                                <span className="text-gray-800 pe-1">“No fun for the writer, no fun for the reader.”</span>No matter what industry you’re working in, as a blogger, you should live and die by this statement.</p>
                                {/*<!--end::Text-*/}
                                {/*<!--begin::Text-*/}
                                <p className="mb-8">Before you do any of the following steps, be sure to pick a topic that actually interests you. Nothing – and 
                                <a href="/../demo8/pages/blog/home.html" className="link-primary pe-1">I mean NOTHING</a>– will kill a blog post more effectively than a lack of enthusiasm from the writer. You can tell when a writer is bored by their subject, and it’s so cringe-worthy it’s a little embarrassing.</p>
                                {/*<!--end::Text-*/}
                                {/*<!--begin::Text-*/}
                                <p className="mb-17">I can hear your objections already. “But Dan, I have to blog for a cardboard box manufacturing company.” I feel your pain, I really do. During the course of my career, I’ve written content for dozens of clients in some less-than-thrilling industries (such as financial regulatory compliance and corporate housing), but the hallmark of a professional blogger is the ability to write well about any topic, no matter how dry it may be. Blogging is a lot easier, however, if you can muster at least a little enthusiasm for the topic at hand.</p>
                                {/*<!--end::Text-*/}
                            </div>
                            {/*<!--end::Description-*/}
                            {/*<!--begin::Block-*/}
                            <div className="d-flex align-items-center border-1 border-dashed card-rounded p-5 p-lg-10 mb-14">
                                {/*<!--begin::Section-*/}
                                <div className="text-center flex-shrink-0 me-7 me-lg-13">
                                    {/*<!--begin::Avatar-*/}
                                    <div className="symbol symbol-70px symbol-circle mb-2">
                                        <img src="/media/avatars/300-2.jpg" className="" alt=""/>
                                    </div>
                                    {/*<!--end::Avatar-*/}
                                    {/*<!--begin::Info-*/}
                                    <div className="mb-0">
                                        <a href="/../demo8/pages/user-profile/overview.html" className="text-gray-700 fw-bold text-hover-primary">Jane Johnson</a>
                                        <span className="text-gray-400 fs-7 fw-semibold d-block mt-1">Co-founder</span>
                                    </div>
                                    {/*<!--end::Info-*/}
                                </div>
                                {/*<!--end::Section-*/}
                                {/*<!--begin::Text-*/}
                                <div className="mb-0 fs-6">
                                    <div className="text-muted fw-semibold lh-lg mb-2">First, a disclaimer – the entire process of writing a blog post often takes more than a couple of hours, even if you can type eighty words per minute and your writing skills are sharp writing a blog post often takes more than a couple.</div>
                                    <a href="/../demo8/pages/user-profile/overview.html" className="fw-semibold link-primary">Author’s Profile</a>
                                </div>
                                {/*<!--end::Text-*/}
                            </div>
                            {/*<!--end::Block-*/}
                            {/*<!--begin::Icons-*/}
                            <div className="d-flex flex-center">
                                {/*<!--begin::Icon-*/}
                                <a href="#" className="mx-4">
                                    <img src="/media/svg/brand-logos/facebook-4.svg" className="h-20px my-2" alt=""/>
                                </a>
                                {/*<!--end::Icon-*/}
                                {/*<!--begin::Icon-*/}
                                <a href="#" className="mx-4">
                                    <img src="/media/svg/brand-logos/instagram-2-1.svg" className="h-20px my-2" alt=""/>
                                </a>
                                {/*<!--end::Icon-*/}
                                {/*<!--begin::Icon-*/}
                                <a href="#" className="mx-4">
                                    <img src="/media/svg/brand-logos/github.svg" className="h-20px my-2" alt=""/>
                                </a>
                                {/*<!--end::Icon-*/}
                                {/*<!--begin::Icon-*/}
                                <a href="#" className="mx-4">
                                    <img src="/media/svg/brand-logos/behance.svg" className="h-20px my-2" alt=""/>
                                </a>
                                {/*<!--end::Icon-*/}
                                {/*<!--begin::Icon-*/}
                                <a href="#" className="mx-4">
                                    <img src="/media/svg/brand-logos/pinterest-p.svg" className="h-20px my-2" alt=""/>
                                </a>
                                {/*<!--end::Icon-*/}
                                {/*<!--begin::Icon-*/}
                                <a href="#" className="mx-4">
                                    <img src="/media/svg/brand-logos/twitter.svg" className="h-20px my-2" alt=""/>
                                </a>
                                {/*<!--end::Icon-*/}
                                {/*<!--begin::Icon-*/}
                                <a href="#" className="mx-4">
                                    <img src="/media/svg/brand-logos/dribbble-icon-1.svg" className="h-20px my-2" alt=""/>
                                </a>
                                {/*<!--end::Icon-*/}
                            </div>
                            {/*<!--end::Icons-*/}
                        </div>
                        {/*<!--end::Post content-*/}
                    </div>
                    {/*<!--end::Content-*/}
                    {/*<!--begin::Sidebar-*/}
                    <div className="flex-column flex-lg-row-auto w-100 w-xl-300px mb-10">
                        {/*<!--begin::Search blog-*/}
                        <div className="mb-16">
                            <h4 className="text-dark mb-7">Search Blog</h4>
                            {/*<!--begin::Input group-*/}
                            <div className="position-relative">
                                {/*<!--begin::Svg Icon | path: icons/duotune/general/gen021.svg-*/}
                                <span className="svg-icon svg-icon-3 svg-icon-gray-500 position-absolute top-50 translate-middle ms-6">
                                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <rect opacity="0.5" x="17.0365" y="15.1223" width="8.15546" height="2" rx="1" transform="rotate(45 17.0365 15.1223)" fill="currentColor"></rect>
                                        <path d="M11 19C6.55556 19 3 15.4444 3 11C3 6.55556 6.55556 3 11 3C15.4444 3 19 6.55556 19 11C19 15.4444 15.4444 19 11 19ZM11 5C7.53333 5 5 7.53333 5 11C5 14.4667 7.53333 17 11 17C14.4667 17 17 14.4667 17 11C17 7.53333 14.4667 5 11 5Z" fill="currentColor"></path>
                                    </svg>
                                </span>
                                {/*<!--end::Svg Icon-*/}
                                <input type="text" className="form-control form-control-solid ps-10" name="search" value="" placeholder="Search"/>
                            </div>
                            {/*<!--end::Input group-*/}
                        </div>
                        {/*<!--end::Search blog-*/}
                        {/*<!--begin::Catigories-*/}
                        <div className="mb-16">
                            <h4 className="text-dark mb-7">Categories</h4>
                            {/*<!--begin::Item-*/}
                            <div className="d-flex flex-stack fw-semibold fs-5 text-muted mb-4">
                                {/*<!--begin::Text-*/}
                                <a href="#" className="text-muted text-hover-primary pe-2">SaaS Solutions</a>
                                {/*<!--end::Text-*/}
                                {/*<!--begin::Number-*/}
                                <div className="m-0">24</div>
                                {/*<!--end::Number-*/}
                            </div>
                            {/*<!--end::Item-*/}
                            {/*<!--begin::Item-*/}
                            <div className="d-flex flex-stack fw-semibold fs-5 text-muted mb-4">
                                {/*<!--begin::Text-*/}
                                <a href="#" className="text-muted text-hover-primary pe-2">Company News</a>
                                {/*<!--end::Text-*/}
                                {/*<!--begin::Number-*/}
                                <div className="m-0">152</div>
                                {/*<!--end::Number-*/}
                            </div>
                            {/*<!--end::Item-*/}
                            {/*<!--begin::Item-*/}
                            <div className="d-flex flex-stack fw-semibold fs-5 text-muted mb-4">
                                {/*<!--begin::Text-*/}
                                <a href="#" className="text-muted text-hover-primary pe-2">Events &amp; Activities</a>
                                {/*<!--end::Text-*/}
                                {/*<!--begin::Number-*/}
                                <div className="m-0">52</div>
                                {/*<!--end::Number-*/}
                            </div>
                            {/*<!--end::Item-*/}
                            {/*<!--begin::Item-*/}
                            <div className="d-flex flex-stack fw-semibold fs-5 text-muted mb-4">
                                {/*<!--begin::Text-*/}
                                <a href="#" className="text-muted text-hover-primary pe-2">Support Related</a>
                                {/*<!--end::Text-*/}
                                {/*<!--begin::Number-*/}
                                <div className="m-0">305</div>
                                {/*<!--end::Number-*/}
                            </div>
                            {/*<!--end::Item-*/}
                            {/*<!--begin::Item-*/}
                            <div className="d-flex flex-stack fw-semibold fs-5 text-muted mb-4">
                                {/*<!--begin::Text-*/}
                                <a href="#" className="text-muted text-hover-primary pe-2">Innovations</a>
                                {/*<!--end::Text-*/}
                                {/*<!--begin::Number-*/}
                                <div className="m-0">70</div>
                                {/*<!--end::Number-*/}
                            </div>
                            {/*<!--end::Item-*/}
                            {/*<!--begin::Item-*/}
                            <div className="d-flex flex-stack fw-semibold fs-5 text-muted">
                                {/*<!--begin::Text-*/}
                                <a href="#" className="text-muted text-hover-primary pe-2">Product Updates</a>
                                {/*<!--end::Text-*/}
                                {/*<!--begin::Number-*/}
                                <div className="m-0">585</div>
                                {/*<!--end::Number-*/}
                            </div>
                            {/*<!--end::Item-*/}
                        </div>
                        {/*<!--end::Catigories-*/}
                        {/*<!--begin::Recent posts-*/}
                        <div className="m-0">
                            <h4 className="text-dark mb-7">Recent Posts</h4>
                            {/*<!--begin::Item-*/}
                            <div className="d-flex flex-stack mb-7">
                                {/*<!--begin::Symbol-*/}
                                <div className="symbol symbol-60px symbol-2by3 me-4">
                                    <div className="symbol-label" style={{backgroundImage: toAbsoluteUrl('/media/stock/600x400/img-1.jpg')}}></div>
                                </div>
                                {/*<!--end::Symbol-*/}
                                {/*<!--begin::Title-*/}
                                <div className="m-0">
                                    <a href="#" className="text-dark fw-bold text-hover-primary fs-6">About Bootstrap Admin</a>
                                    <span className="text-gray-600 fw-semibold d-block pt-1 fs-7">We’ve been a focused on making a the sky</span>
                                </div>
                                {/*<!--end::Title-*/}
                            </div>
                            {/*<!--end::Item-*/}
                            {/*<!--begin::Item-*/}
                            <div className="d-flex flex-stack mb-7">
                                {/*<!--begin::Symbol-*/}
                                <div className="symbol symbol-60px symbol-2by3 me-4">
                                    <div className="symbol-label" style={{backgroundImage:toAbsoluteUrl('/media/stock/600x400/img-2.jpg')}}></div>
                                </div>
                                {/*<!--end::Symbol-*/}
                                {/*<!--begin::Title-*/}
                                <div className="m-0">
                                    <a href="#" className="text-dark fw-bold text-hover-primary fs-6">A yellow sofa</a>
                                    <span className="text-gray-600 fw-semibold d-block pt-1 fs-7">We’ve been a focused on making a the sky</span>
                                </div>
                                {/*<!--end::Title-*/}
                            </div>
                            {/*<!--end::Item-*/}
                            {/*<!--begin::Item-*/}
                            <div className="d-flex flex-stack mb-7">
                                {/*<!--begin::Symbol-*/}
                                <div className="symbol symbol-60px symbol-2by3 me-4">
                                    <div className="symbol-label" style={{backgroundImage:toAbsoluteUrl('/media/stock/600x400/img-3.jpg')}}></div>
                                </div>
                                {/*<!--end::Symbol-*/}
                                {/*<!--begin::Title-*/}
                                <div className="m-0">
                                    <a href="#" className="text-dark fw-bold text-hover-primary fs-6">Our Camra Mega Set</a>
                                    <span className="text-gray-600 fw-semibold d-block pt-1 fs-7">We’ve been a focused on making a the sky</span>
                                </div>
                                {/*<!--end::Title-*/}
                            </div>
                            {/*<!--end::Item-*/}
                            {/*<!--begin::Item-*/}
                            <div className="d-flex flex-stack">
                                {/*<!--begin::Symbol-*/}
                                <div className="symbol symbol-60px symbol-2by3 me-4">
                                    <div className="symbol-label" style={{backgroundImage:toAbsoluteUrl('/media/stock/600x400/img-4.jpg')}}></div>
                                </div>
                                {/*<!--end::Symbol-*/}
                                {/*<!--begin::Title-*/}
                                <div className="m-0">
                                    <a href="#" className="text-dark fw-bold text-hover-primary fs-6">Time to cook and eat?</a>
                                    <span className="text-gray-600 fw-semibold d-block pt-1 fs-7">We’ve been a focused on making a the sky</span>
                                </div>
                                {/*<!--end::Title-*/}
                            </div>
                            {/*<!--end::Item-*/}
                        </div>
                        {/*<!--end::Recent posts-*/}
                    </div>
                    {/*<!--end::Sidebar-*/}
                </div>
                {/*<!--end::Layout-*/}
                {/*<!--begin::Section-*/}
                <div className="mb-17">
                    {/*<!--begin::Content-*/}
                    <div className="d-flex flex-stack mb-5">
                        {/*<!--begin::Title-*/}
                        <h3 className="text-dark">Video Tutorials</h3>
                        {/*<!--end::Title-*/}
                        {/*<!--begin::Link-*/}
                        <a href="#" className="fs-6 fw-semibold link-primary">View All Videos</a>
                        {/*<!--end::Link-*/}
                    </div>
                    {/*<!--end::Content-*/}
                    {/*<!--begin::Separator-*/}
                    <div className="separator separator-dashed mb-9"></div>
                    {/*<!--end::Separator-*/}
                    {/*<!--begin::Row-*/}
                    <div className="row g-10">
                        {/*<!--begin::Col-*/}
                        <div className="col-md-4">
                            {/*<!--begin::Feature post-*/}
                            <div className="card-xl-stretch me-md-6">
                                {/*<!--begin::Image-*/}
                                <a className="d-block bgi-no-repeat bgi-size-cover bgi-position-center card-rounded position-relative min-h-175px mb-5" style={{backgroundImage:toAbsoluteUrl('/media/stock/600x400/img-73.jpg')}} data-fslightbox="lightbox-video-tutorials" href="https://www.youtube.com/embed/btornGtLwIo">
                                    <img src="/media/svg/misc/video-play.svg" className="position-absolute top-50 start-50 translate-middle" alt=""/>
                                </a>
                                {/*<!--end::Image-*/}
                                {/*<!--begin::Body-*/}
                                <div className="m-0">
                                    {/*<!--begin::Title-*/}
                                    <a href="/../demo8/pages/user-profile/overview.html" className="fs-4 text-dark fw-bold text-hover-primary text-dark lh-base">Admin Panel - How To Started the Dashboard Tutorial</a>
                                    {/*<!--end::Title-*/}
                                    {/*<!--begin::Text-*/}
                                    <div className="fw-semibold fs-5 text-gray-600 text-dark my-4">We’ve been focused on making a the from also not been afraid to and step away been focused create eye</div>
                                    {/*<!--end::Text-*/}
                                    {/*<!--begin::Content-*/}
                                    <div className="fs-6 fw-bold">
                                        {/*<!--begin::Author-*/}
                                        <a href="/../demo8/pages/user-profile/overview.html" className="text-gray-700 text-hover-primary">Jane Miller</a>
                                        {/*<!--end::Author-*/}
                                        {/*<!--begin::Date-*/}
                                        <span className="text-muted">on Mar 21 2021</span>
                                        {/*<!--end::Date-*/}
                                    </div>
                                    {/*<!--end::Content-*/}
                                </div>
                                {/*<!--end::Body-*/}
                            </div>
                            {/*<!--end::Feature post-*/}
                        </div>
                        {/*<!--end::Col-*/}
                        {/*<!--begin::Col-*/}
                        <div className="col-md-4">
                            {/*<!--begin::Feature post-*/}
                            <div className="card-xl-stretch mx-md-3">
                                {/*<!--begin::Image-*/}
                                <a className="d-block bgi-no-repeat bgi-size-cover bgi-position-center card-rounded position-relative min-h-175px mb-5" style={{backgroundImage:toAbsoluteUrl('/media/stock/600x400/img-74.jpg')}} data-fslightbox="lightbox-video-tutorials" href="https://www.youtube.com/embed/btornGtLwIo">
                                    <img src="/media/svg/misc/video-play.svg" className="position-absolute top-50 start-50 translate-middle" alt=""/>
                                </a>
                                {/*<!--end::Image-*/}
                                {/*<!--begin::Body-*/}
                                <div className="m-0">
                                    {/*<!--begin::Title-*/}
                                    <a href="/../demo8/pages/user-profile/overview.html" className="fs-4 text-dark fw-bold text-hover-primary text-dark lh-base">Admin Panel - How To Started the Dashboard Tutorial</a>
                                    {/*<!--end::Title-*/}
                                    {/*<!--begin::Text-*/}
                                    <div className="fw-semibold fs-5 text-gray-600 text-dark my-4">We’ve been focused on making the from v4 to v5 but we have also not been afraid to step away been focused</div>
                                    {/*<!--end::Text-*/}
                                    {/*<!--begin::Content-*/}
                                    <div className="fs-6 fw-bold">
                                        {/*<!--begin::Author-*/}
                                        <a href="/../demo8/pages/user-profile/overview.html" className="text-gray-700 text-hover-primary">Cris Morgan</a>
                                        {/*<!--end::Author-*/}
                                        {/*<!--begin::Date-*/}
                                        <span className="text-muted">on Apr 14 2021</span>
                                        {/*<!--end::Date-*/}
                                    </div>
                                    {/*<!--end::Content-*/}
                                </div>
                                {/*<!--end::Body-*/}
                            </div>
                            {/*<!--end::Feature post-*/}
                        </div>
                        {/*<!--end::Col-*/}
                        {/*<!--begin::Col-*/}
                        <div className="col-md-4">
                            {/*<!--begin::Feature post-*/}
                            <div className="card-xl-stretch ms-md-6">
                                {/*<!--begin::Image-*/}
                                <a className="d-block bgi-no-repeat bgi-size-cover bgi-position-center card-rounded position-relative min-h-175px mb-5" style={{backgroundImage:toAbsoluteUrl('/media/stock/600x400/img-47.jpg')}} data-fslightbox="lightbox-video-tutorials" href="https://www.youtube.com/embed/TWdDZYNqlg4">
                                    <img src="/media/svg/misc/video-play.svg" className="position-absolute top-50 start-50 translate-middle" alt=""/>
                                </a>
                                {/*<!--end::Image-*/}
                                {/*<!--begin::Body-*/}
                                <div className="m-0">
                                    {/*<!--begin::Title-*/}
                                    <a href="/../demo8/pages/user-profile/overview.html" className="fs-4 text-dark fw-bold text-hover-primary text-dark lh-base">Admin Panel - How To Started the Dashboard Tutorial</a>
                                    {/*<!--end::Title-*/}
                                    {/*<!--begin::Text-*/}
                                    <div className="fw-semibold fs-5 text-gray-600 text-dark my-4">We’ve been focused on making the from v4 to v5 but we’ve also not been afraid to step away been focused</div>
                                    {/*<!--end::Text-*/}
                                    {/*<!--begin::Content-*/}
                                    <div className="fs-6 fw-bold">
                                        {/*<!--begin::Author-*/}
                                        <a href="/../demo8/pages/user-profile/overview.html" className="text-gray-700 text-hover-primary">Carles Nilson</a>
                                        {/*<!--end::Author-*/}
                                        {/*<!--begin::Date-*/}
                                        <span className="text-muted">on May 14 2021</span>
                                        {/*<!--end::Date-*/}
                                    </div>
                                    {/*<!--end::Content-*/}
                                </div>
                                {/*<!--end::Body-*/}
                            </div>
                            {/*<!--end::Feature post-*/}
                        </div>
                        {/*<!--end::Col-*/}
                    </div>
                    {/*<!--end::Row-*/}
                </div>
                {/*<!--end::Section-*/}
                {/*<!--begin::Section-*/}
                <div className="mb-17">
                    {/*<!--begin::Content-*/}
                    <div className="d-flex flex-stack mb-5">
                        {/*<!--begin::Title-*/}
                        <h3 className="text-dark">Hottest Bundles</h3>
                        {/*<!--end::Title-*/}
                        {/*<!--begin::Link-*/}
                        <a href="#" className="fs-6 fw-semibold link-primary">View All Offers</a>
                        {/*<!--end::Link-*/}
                    </div>
                    {/*<!--end::Content-*/}
                    {/*<!--begin::Separator-*/}
                    <div className="separator separator-dashed mb-9"></div>
                    {/*<!--end::Separator-*/}
                    {/*<!--begin::Row-*/}
                    <div className="row g-10">
                        {/*<!--begin::Col-*/}
                        <div className="col-md-4">
                            {/*<!--begin::Hot sales post-*/}
                            <div className="card-xl-stretch me-md-6">
                                {/*<!--begin::Overlay-*/}
                                <a className="d-block overlay" data-fslightbox="lightbox-hot-sales" href="/media/stock/600x400/img-23.jpg">
                                    {/*<!--begin::Image-*/}
                                    <div className="overlay-wrapper bgi-no-repeat bgi-position-center bgi-size-cover card-rounded min-h-175px" style={{backgroundImage:toAbsoluteUrl('/media/stock/600x400/img-23.jpg')}}></div>
                                    {/*<!--end::Image-*/}
                                    {/*<!--begin::Action-*/}
                                    <div className="overlay-layer card-rounded bg-dark bg-opacity-25">
                                        <i className="bi bi-eye-fill fs-2x text-white"></i>
                                    </div>
                                    {/*<!--end::Action-*/}
                                </a>
                                {/*<!--end::Overlay-*/}
                                {/*<!--begin::Body-*/}
                                <div className="mt-5">
                                    {/*<!--begin::Title-*/}
                                    <a href="#" className="fs-4 text-dark fw-bold text-hover-primary text-dark lh-base">25 Products Mega Bundle with 50% off discount amazing</a>
                                    {/*<!--end::Title-*/}
                                    {/*<!--begin::Text-*/}
                                    <div className="fw-semibold fs-5 text-gray-600 text-dark mt-3">We’ve been focused on making a the from also not been eye</div>
                                    {/*<!--end::Text-*/}
                                    {/*<!--begin::Text-*/}
                                    <div className="fs-6 fw-bold mt-5 d-flex flex-stack">
                                        {/*<!--begin::Label-*/}
                                        <span className="badge border border-dashed fs-2 fw-bold text-dark p-2">
                                        <span className="fs-6 fw-semibold text-gray-400">$</span>28</span>
                                        {/*<!--end::Label-*/}
                                        {/*<!--begin::Action-*/}
                                        <a href="#" className="btn btn-sm btn-primary">Purchase</a>
                                        {/*<!--end::Action-*/}
                                    </div>
                                    {/*<!--end::Text-*/}
                                </div>
                                {/*<!--end::Body-*/}
                            </div>
                            {/*<!--end::Hot sales post-*/}
                        </div>
                        {/*<!--end::Col-*/}
                        {/*<!--begin::Col-*/}
                        <div className="col-md-4">
                            {/*<!--begin::Hot sales post-*/}
                            <div className="card-xl-stretch mx-md-3">
                                {/*<!--begin::Overlay-*/}
                                <a className="d-block overlay" data-fslightbox="lightbox-hot-sales" href="/media/stock/600x600/img-14.jpg">
                                    {/*<!--begin::Image-*/}
                                    <div className="overlay-wrapper bgi-no-repeat bgi-position-center bgi-size-cover card-rounded min-h-175px" style={{backgroundImage:toAbsoluteUrl('/media/stock/600x600/img-14.jpg')}}></div>
                                    {/*<!--end::Image-*/}
                                    {/*<!--begin::Action-*/}
                                    <div className="overlay-layer card-rounded bg-dark bg-opacity-25">
                                        <i className="bi bi-eye-fill fs-2x text-white"></i>
                                    </div>
                                    {/*<!--end::Action-*/}
                                </a>
                                {/*<!--end::Overlay-*/}
                                {/*<!--begin::Body-*/}
                                <div className="mt-5">
                                    {/*<!--begin::Title-*/}
                                    <a href="#" className="fs-4 text-dark fw-bold text-hover-primary text-dark lh-base">25 Products Mega Bundle with 50% off discount amazing</a>
                                    {/*<!--end::Title-*/}
                                    {/*<!--begin::Text-*/}
                                    <div className="fw-semibold fs-5 text-gray-600 text-dark mt-3">We’ve been focused on making a the from also not been eye</div>
                                    {/*<!--end::Text-*/}
                                    {/*<!--begin::Text-*/}
                                    <div className="fs-6 fw-bold mt-5 d-flex flex-stack">
                                        {/*<!--begin::Label-*/}
                                        <span className="badge border border-dashed fs-2 fw-bold text-dark p-2">
                                        <span className="fs-6 fw-semibold text-gray-400">$</span>27</span>
                                        {/*<!--end::Label-*/}
                                        {/*<!--begin::Action-*/}
                                        <a href="#" className="btn btn-sm btn-primary">Purchase</a>
                                        {/*<!--end::Action-*/}
                                    </div>
                                    {/*<!--end::Text-*/}
                                </div>
                                {/*<!--end::Body-*/}
                            </div>
                            {/*<!--end::Hot sales post-*/}
                        </div>
                        {/*<!--end::Col-*/}
                        {/*<!--begin::Col-*/}
                        <div className="col-md-4">
                            {/*<!--begin::Hot sales post-*/}
                            <div className="card-xl-stretch ms-md-6">
                                {/*<!--begin::Overlay-*/}
                                <a className="d-block overlay" data-fslightbox="lightbox-hot-sales" href="/media/stock/600x400/img-71.jpg">
                                    {/*<!--begin::Image-*/}
                                    <div className="overlay-wrapper bgi-no-repeat bgi-position-center bgi-size-cover card-rounded min-h-175px" style={{backgroundImage:toAbsoluteUrl('/media/stock/600x400/img-71.jpg')}}></div>
                                    {/*<!--end::Image-*/}
                                    {/*<!--begin::Action-*/}
                                    <div className="overlay-layer card-rounded bg-dark bg-opacity-25">
                                        <i className="bi bi-eye-fill fs-2x text-white"></i>
                                    </div>
                                    {/*<!--end::Action-*/}
                                </a>
                                {/*<!--end::Overlay-*/}
                                {/*<!--begin::Body-*/}
                                <div className="mt-5">
                                    {/*<!--begin::Title-*/}
                                    <a href="#" className="fs-4 text-dark fw-bold text-hover-primary text-dark lh-base">25 Products Mega Bundle with 50% off discount amazing</a>
                                    {/*<!--end::Title-*/}
                                    {/*<!--begin::Text-*/}
                                    <div className="fw-semibold fs-5 text-gray-600 text-dark mt-3">We’ve been focused on making a the from also not been eye</div>
                                    {/*<!--end::Text-*/}
                                    {/*<!--begin::Text-*/}
                                    <div className="fs-6 fw-bold mt-5 d-flex flex-stack">
                                        {/*<!--begin::Label-*/}
                                        <span className="badge border border-dashed fs-2 fw-bold text-dark p-2">
                                        <span className="fs-6 fw-semibold text-gray-400">$</span>25</span>
                                        {/*<!--end::Label-*/}
                                        {/*<!--begin::Action-*/}
                                        <a href="#" className="btn btn-sm btn-primary">Purchase</a>
                                        {/*<!--end::Action-*/}
                                    </div>
                                    {/*<!--end::Text-*/}
                                </div>
                                {/*<!--end::Body-*/}
                            </div>
                            {/*<!--end::Hot sales post-*/}
                        </div>
                        {/*<!--end::Col-*/}
                    </div>
                    {/*<!--end::Row-*/}
                </div>
                {/*<!--end::Section-*/}
            </div>
            {/*<!--end::Body-*/}
        </div>
    </>
  )
}

export {PostPage};