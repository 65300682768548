import React, { useRef }  from "react"
import { PageTitle } from "../../../_metronic/layout/core"
import { useAuth } from "../../modules/auth";
import { KTSVG } from "../../../_metronic/helpers"
import { Container, Row, Col, Button, Alert } from "react-bootstrap";
import { useEthers, shortenCryptoAddress } from "../../modules/ethers/ethersProvider";
import { ethers } from "ethers";
import '../../../_metronic/assets/css/cardBorders.css';
import { useThemeMode } from "../../../_metronic/partials";
import axios from 'axios'

const FAUCET_REQUEST_FUNDS:string = `${process.env.REACT_APP_API_URL}/faucet/requestFunds`
const faucetAddr:string = `0xfa4c3700589c6a6a7a193ea661e5e39a305dda66`

export async function faucetRequestFunds(user_id: number, api_token: string) {
   return await axios.post<any>(FAUCET_REQUEST_FUNDS, {
    user_id,
    api_token,
  });
}

const Faucet:React.FC = () => {
    const { currentUser } = useAuth()
    const { connect, isConnected, connected, address, provider, signer, block, chainId, balance, chainData, switchChain } = useEthers()

    const [userBalance, setUserBalance] = React.useState('0.0')
    const [faucetBalance, setFaucetBalance] = React.useState('0.0')
    const [loading, setLoading] = React.useState([false, false])

    const [alertMessage, setAlertMessage] = React.useState('Standby')
    const [alertShow, setAlertShow] = React.useState(false)
    const [alertStatus, setAlertStatus] = React.useState('secondary')

    const {mode, menuMode, updateMode, updateMenuMode} = useThemeMode()


    //const eth:any = window.ethereum._state.accounts[0].
     
    React.useEffect(() => {
        (async function() {
            if (!connected) connect()
            refreshBalances()
        })()
    }, [connected])

    const refreshBalances = async function(){
        if (loading[1]) return
        loading[1] = true
        setLoading([...loading])
        try {
            if (provider) {
                setFaucetBalance(ethers.utils.formatEther(await provider.getBalance(faucetAddr)).toString());
                setUserBalance(ethers.utils.formatEther(await provider.getBalance( currentUser !== undefined ? currentUser.crypto_address : '' )).toString());
            }
        } catch (e) {
            
        }
        setTimeout(() => { loading[1] = false; setLoading([...loading]) }, 1000)
        console.log(`refreshBalances`, loading)
     }
/**
 * 
 * @returns 
 */
 const initiateFundsRequest = async function(){
    if (loading[0]) return
    loading[0] = true
    setLoading([...loading])
    console.log(`initiate funds`, loading)
    try {
        if (currentUser !== undefined) { 
            const response:any = (await faucetRequestFunds(currentUser.id, currentUser.api_token)).data
            if (response.result === true) {
                setAlertStatus('success')
            } else {   
                setAlertStatus('danger')
            }
            console.log(response)
            setAlertMessage(response.response)
        }
    } catch (e) {
        setAlertMessage('Error requesting funds from faucet')
    }
    setAlertShow(true)
    setTimeout(() => { loading[0] = false; setLoading([...loading]) }, 1000)
 }

 /**
  * 
  * @returns Table/component if Goerli network is connected
  */
 const goerliAddressData = () => (
    <>
        <div className={`table-responsive mb-4`}>
            <table className={`table table-striped table-light table-hover `}>
                <thead>
                    <tr>
                        <th scope="col" className="w-50 fw-bold fs-2">Address</th>
                        <th scope="col" className="w-50 fw-bold fs-2">Balance</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td><a href={address ? `https://goerli.etherscan.io/address/${address}` : ''}>{currentUser && shortenCryptoAddress(currentUser.crypto_address)}</a> (You)</td>
                        <td><a href={address ? `https://goerli.etherscan.io/address/${address}` : ''}>{userBalance}</a></td>
                    </tr>
                    <tr>
                        <td><a href={faucetBalance ? `https://goerli.etherscan.io/address/${faucetAddr}` : ''}>{shortenCryptoAddress(faucetAddr)}</a> (Faucet)</td>
                        <td><a href={faucetBalance ? `https://goerli.etherscan.io/address/${faucetAddr}` : ''}>{faucetBalance}</a></td>
                    </tr>
                </tbody>
            </table>
        </div>
        <Container className={'mb-4'}>
            <Row>
                <Col>
                    <Button onClick={initiateFundsRequest} disabled={loading.indexOf(true) !== -1}>{loading[0] === false && <span className='indicator-label'>Request Funds</span>}
                        {loading[0] === true && (
                        <span className='indicator-progress' style={{display: 'block'}}>
                            Waiting for response from server..
                            <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                        </span>
                    )}</Button>
                </Col>
                <Col>
                    <Button onClick={refreshBalances} disabled={loading.indexOf(true) !== -1}>{loading[1] === false && <span className='indicator-label'>Refresh Balances</span>}
                        {loading[1] === true && (
                            <span className='indicator-progress' style={{display: 'block'}}>
                            Waiting for response from server..
                            <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                            </span>
                    )}</Button>
                </Col>
            </Row>
        </Container>
        <p className="mb-4">If you have unused Goerli ETH, please send them to {faucetAddr}</p>
        { alertShow && 
            <Alert variant={alertStatus} onClose={() => setAlertShow(false)} dismissible>
                {alertMessage}
            </Alert>
        }
    </>
)

//Switch to Goerli Network button clicked
const switchGoerliNetwork =  () => (
    <>
        <Button onClick={switchChain}>Switch to Goerli (Görli) Network</Button>
    </>
)



 const pageTitle = `Goerli (Görli) Faucet`
return (
    <>
        <PageTitle>{pageTitle}</PageTitle>
        <Container fluid>
            <Row>
                <Col className='text-center'>
                    <KTSVG path='/media/icons/duotune/medicine/med005.svg' className={`svg-icon-primary svg-icon-3x ms-n1`} />
                    <div className={`text-gray-900 fw-bold fs-2 mb-2 mt-5`}>{pageTitle}</div>
                    <div className={`fw-semibold text-gray-500`}>
                        <p className="mb-0">Get free 0.2 Goerli testnet ETH daily.</p>
                        <p>Visit <a href="https://goerli-faucet.pk910.de/" target="_blank" rel="noreferrer">here</a> for a PoW browser mining faucet.</p>
                    </div>
                </Col>
            </Row>
            <Row>
                <Col className='col-xl-6 offset-xl-3 text-center'>
                    <div className={`card  bg-gray-200  hoverable card-lg-stretch mb-xl-8 card-body card-borders ${mode === 'dark' ? 'card-gradient' : ''}`} >
                            {chainId && chainId === '5' ? goerliAddressData() : switchGoerliNetwork()}
                    </div>
                </Col>  
            </Row>
        </Container>
    </>
  )
}

export {Faucet};