import React  from "react"
import { PageTitle } from "../../_metronic/layout/core"
import { useAuth } from "../modules/auth";
import { useEthers } from "../modules/ethers/ethersProvider";
import { toAbsoluteUrl } from "../../_metronic/helpers";
import { KTSVG } from "../../_metronic/helpers";
import { Link } from "react-router-dom";
import '../../_metronic/assets/css/cardBorders.css';

const HomePage:React.FC = () => {
    const { currentUser } = useAuth()
    const ethereum  = useEthers()

    React.useEffect(() => {
        
    }, [])

    const pageTitle = `Home`

return (
    <>
        <PageTitle>{pageTitle}</PageTitle>
        <div className="card">
            {/*<!--begin::Body-->*/}
            <div className="card-body p-lg-15 pb-lg-0">
                {/*<!--begin::Layout-->*/}
                <div className="d-flex flex-column flex-xl-row">
                    {/*<!--begin::Content-->*/}
                    <div className="flex-lg-row-fluid me-xl-15">
                        {/*<!--begin::Post content-->*/}
                        <div className="mb-17">
                            {/*<!--begin::Wrapper-->*/}
                            <div className="mb-8">
                                {/*<!--begin::Info-->*/}
                                <div className="d-flex flex-wrap mb-6">
                                    {/*<!--begin::Item-->*/}
                                    <div className="me-9 my-1">
                                        {/*<!--begin::Icon-->*/}
                                        {/*<!--begin::Svg Icon | path: icons/duotune/general/gen001.svg-->*/}
                                        <KTSVG path='media/icons/duotune/general/gen001.svg' className={`svg-icon-primary svg-icon-1x ms-n1 m-4`} />
                                        {/*<!--end::Svg Icon-->*/}
                                        {/*<!--end::Icon-->*/}
                                        {/*<!--begin::Label-->*/}
                                        <span className="fw-bold text-gray-600">July 2022</span>
                                        {/*<!--end::Label-->*/}
                                    </div>
                                    {/*<!--end::Item-->*/}
                                    {/*<!--begin::Item-->*/}
                                    <div className="me-9 my-1">
                                        {/*<!--begin::Icon-->*/}
                                        {/*<!--SVG file not found: icons/duotune/finance/fin006.svgFolder.svg-->*/}
                                        {/*<!--end::Icon-->*/}
                                        {/*<!--begin::Label-->*/}
                                        <span className="fw-bold text-gray-600">Introduction</span>
                                        {/*<!--begin::Label-->*/}
                                    </div>
                                    {/*<!--end::Item-->*/}
                                    {/*<!--begin::Item-->*/}
                                    <div className="my-1">
                                        {/*<!--begin::Icon-->*/}
                                        {/*<!--begin::Svg Icon | path: icons/duotune/communication/com003.svg-->/}
                                        <span className="svg-icon svg-icon-primary svg-icon-2 me-1">
                                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path opacity="0.3" d="M2 4V16C2 16.6 2.4 17 3 17H13L16.6 20.6C17.1 21.1 18 20.8 18 20V17H21C21.6 17 22 16.6 22 16V4C22 3.4 21.6 3 21 3H3C2.4 3 2 3.4 2 4Z" fill="currentColor"></path>
                                                <path d="M18 9H6C5.4 9 5 8.6 5 8C5 7.4 5.4 7 6 7H18C18.6 7 19 7.4 19 8C19 8.6 18.6 9 18 9ZM16 12C16 11.4 15.6 11 15 11H6C5.4 11 5 11.4 5 12C5 12.6 5.4 13 6 13H15C15.6 13 16 12.6 16 12Z" fill="currentColor"></path>
                                            </svg>
                                        </span>
                                        {/*<!--end::Svg Icon-->*/}
                                        {/*<!--end::Icon-->*/}
                                        {/*<!--begin::Label-->/}
                                        <span className="fw-bold text-gray-400">24 Comments</span>
                                        {/*<!--end::Label-->*/}
                                    </div>
                                    {/*<!--end::Item-->*/}
                                </div>
                                {/*<!--end::Info-->*/}
                                {/*<!--begin::Title-->*/}
                                <a href="#" className="text-dark text-hover-primary fs-2 fw-bold">DeFiDemo &amp; Decentralizing.finance <span className="fw-bold text-muted fs-5 ps-1"></span></a>
                                {/*<!--end::Title-->*/}
                                {/*<!--begin::Container-->*/}
                                <div className="overlay mt-8 text-center" style={{width:'100%'}}>
                                    {/*<!--begin::Image-->*/}
                                    {/*<div  style={{maxWidth: '600px', display: 'inline-block'}} >
                                        <img className="img-fluid card-rounded mx-auto d-block" src={toAbsoluteUrl('/media/stock/1600x800/41978111975_fcec422dfc_b_crop.jpg')}/>
                                    </div>*/}
                                    {/*<!--end::Image-->*/}
                                    {/*<!--begin::Links-->/}
                                    <div className="overlay-layer card-rounded bg-dark bg-opacity-25">
                                        <a href="#" className="btn btn-primary">About Us</a>
                                        <a href="#" className="btn btn-light-primary ms-3">Join Us</a>
                                    </div>
                                    {/*<!--end::Links-->*/}
                                </div>
                                {/*<!--end::Container-->*/}
                            </div>
                            {/*<!--end::Wrapper-->*/}
                            {/*<!--begin::Description-->*/}
                            <div className="fs-5 fw-semibold text-dark">
                                {/*<!--begin::Text-->*/}
                                <p className="mb-8">Welcome to DeFiDemo, a crypto based project that I started in July, 2022. DeFiDemo is built using a combination of modern web and web3 technologies. The primary purpose here is to serve as a testnet or sandbox for interfacing smart contracts &amp; the blockchain to the web. </p>
                                <p className="mb-8">To login, a cryptographic signature is required from a common web3 wallet (Metamask, Coinbase, Crypto.com, etc) to access authenticated portions of decentralizing.finance. While you may use any address you wish for testing, we’d recommend you use a new/empty address for testing. You can get testnet ETH automatically for free through our provided faucet. </p>
                                <p className="mb-8">
                                Features will include:
                                    <ul className='text-gray-700'>
                                        <li>ERC-20 factory to generate and manage tokens, allowing a user to mint/burn/pause on demand. </li>
                                        <li>ERC-20 swaps with Uniswapv2, possibly allow users to add liquidity for custom tokens</li>
                                        <li>ERC-20 staking pools and a factory to allow users to generate custom staking pools</li>
                                        <li>ERC-721 custom NFT collection, integrated to Opensea’s testnet</li>
                                        <li>ERC-721 cross-chain NFT cloner</li>
                                        <li>..and more</li>
                                    </ul>
                                </p>
                                <p className="mb-8">If you appreciate my work or want to get in touch with me, feel free to send me an e-mail or add me on <a href="https://linkedin.com/in/adavin" target="_blank" className="link-primary">LinkedIn</a>!</p>

                            </div>
                            {/*<!--end::Description-->*/}
                            {/*<!--begin::Block-->*/}
                            <div className="d-flex align-items-center border-1 border-dashed card-rounded p-5 p-lg-10 mb-14">
                                {/*<!--begin::Section-->*/}
                                <div className="text-center flex-shrink-0 me-7 me-lg-13">
                                    {/*<!--begin::Avatar-->*/}
                                    <div className="symbol symbol-70px symbol-circle mb-2">
                                        <img src="/media/photos/1650304863974.jpg" className="" alt=""/>
                                    </div>
                                    {/*<!--end::Avatar-->*/}
                                    {/*<!--begin::Info-->*/}
                                    <div className="mb-0">
                                        <a href="https://da.vin/" target="_blank" className="text-dark fw-bold text-hover-primary">Alex Davin</a>
                                        {/*<!--begin::Icons-->*/}
                                        <div className="d-flex flex-center">
                                            {/*<!--begin::Icon-->*/}
                                            <a href="https://linkedin.com/in/adavin" className="mx-4">
                                                <img src="/media/svg/brand-logos/linkedin-2.svg" className="h-20px my-2" alt=""/>
                                            </a>
                                            {/*<!--end::Icon-->*/}
                                            {/*<!--begin::Icon-->*/}
                                            <a href="https://github.com/adavin" className="mx-4">
                                                <img src="/media/svg/brand-logos/github-1.svg" className="h-20px my-2" alt=""/>
                                            </a>
                                            {/*<!--end::Icon-->*/}
                                        </div>
                                        {/*<!--end::Icons-->*/}
                                        {/*<span className="text-gray-400 fs-7 fw-semibold d-block mt-1">decentralizing.finance</span>*/}
                                    </div>
                                    {/*<!--end::Info-->*/}
                                </div>
                                {/*<!--end::Section-->*/}
                                {/*<!--begin::Text-->*/}
                                <div className="mb-0 fs-6">
                                    <div className="text-gray-700 fw-semibold lh-lg mb-2">I'm a software developer for web &amp; blockchain integrations.</div>
                                    {/*<div className="text-gray-700 fw-semibold lh-lg mb-2">This site was developed using React, Solidity, Ethers, NestJS, MySQL, a custom MetaMask provider I wrote for this project, and a few other things.  </div>*/}
                                    <div className="text-gray-700 fw-semibold lh-lg mb-2"><a href="mailto:alex@decentralizing.finance">alex@decentralizing.finance</a></div>
                                </div>
                                {/*<!--end::Text-->*/}
                            </div>
                            {/*<!--end::Block-->*/}
                        </div>
                        {/*<!--end::Post content-->*/}
                    </div>
                    {/*<!--end::Content-->*/}
                    
                </div>
                {/*<!--end::Layout-->*/}
            </div>
            {/*<!--end::Body-->*/}
        </div>
    </>
  )
}

export {HomePage};