import React  from "react"
import { PageTitle } from "../../_metronic/layout/core"
import { useAuth } from "../modules/auth";
import { Container, Row, Col } from "react-bootstrap";
const DefaultPage:React.FC = () => {
    const { currentUser } = useAuth()
    
    React.useEffect(() => {
        
    }, [])

    const pageTitle = `Connected as ${currentUser?.crypto_address}`

return (
    <>
        <PageTitle>{pageTitle}</PageTitle>
        <Container fluid>
            <Row>
                <Col className="col-xl-12 text-center">
                    <div className={`card bg-body-white hoverable card-lg-stretch mb-xl-8 card-body`}>
                        <h1>Default page</h1>
                        <p>{pageTitle}</p>
                    </div>
                </Col>
            </Row>
        </Container>
    </>
  )
}

export {DefaultPage};