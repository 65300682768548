import { useEffect, useRef, useState }  from "react"
import { PageTitle } from "../../../_metronic/layout/core"
import { useAuth } from "../../modules/auth";
import { Container, Row, Col, Form, Button, Alert } from "react-bootstrap";
import { KTSVG } from "../../../_metronic/helpers";
import { useEthers } from "../../modules/ethers/ethersProvider";
import { BigNumber, ethers } from "ethers";
import { useNavigate } from "react-router-dom";
import { DFDToken } from "./DFDTokens";
import { UserToken } from "./UserTokens";
import '../../../_metronic/assets/css/cardBorders.css';
import axios from "axios";
import { useThemeMode } from "../../../_metronic/partials";
/*
 
const abi = [
    // Read-Only Functions
    "function balanceOf(address owner) view returns (uint256)",
    "function decimals() view returns (uint8)",
    "function symbol() view returns (string)",

    // Authenticated Functions
    "function transfer(address to, uint amount) returns (bool)",

    // Events
    "event Transfer(address indexed from, address indexed to, uint amount)"
];

function createToken(uint256 initialSupply, string memory name, string memory symbol) public returns (address tokenResult) {
*/

export class NewUserTokenDto {
user_id: number;
api_token: string;
token_address: string;
token_name: string;
token_symbol: string;
initial_supply: string;
txid: string;
    constructor () {
        this.user_id = 0;
        this.api_token = ''
        this.token_address = ''
        this.token_name = ''
        this.token_symbol = ''
        this.initial_supply = ''
        this.txid = ''
    }
}
const TOKENS_CREATE_USER_TOKEN:string = `${process.env.REACT_APP_API_URL}/tokens/newUserToken`
const TOKENS_GET_USER_TOKENS:string = `${process.env.REACT_APP_API_URL}/tokens/getUserTokens`

export async function newUserToken(newUserTokenDto: NewUserTokenDto) {
    return await axios.post<NewUserTokenDto>(TOKENS_CREATE_USER_TOKEN, newUserTokenDto);
 }

 export async function getUserTokens(user_id: number, api_token: string) {
    return await axios.post<any>(TOKENS_GET_USER_TOKENS, {user_id, api_token});
 }

export interface TokenFactoryContract extends ethers.Contract {
    createToken: (supply: BigNumber, name: string, symbol: string) => Promise<any>
}


//export const TOKEN_FACTORY_ADDRESS = '0x5FC8d32690cc91D4c39d9d3abcBD16989F875707' //hardhat
export const TOKEN_FACTORY_ADDRESS = '0xaddb9f60490241903d3555a61ffcf81abf58cfe4' //goerli
export const tokenFactoryABI = ["function createToken(uint256 initialSupply, string memory name, string memory symbol) public returns (address tokenResult)"];

/** Ganache */
/*
export const dfdTokens = [{tokenName:"defi-USD", tokenSymbol:"dfdUSD", initialSupply:"1000000000000.0", address:"0x2Fe95e58F2D810353c1c03fa1F67a92543498eaD", maxMint: "20000"},
{tokenName:"defi-Bitcoin", tokenSymbol:"dfdBTC", initialSupply:"19000000.0", address:"0xCA4C960a459e0D63ad1e8DAB99f6F935E1719e2b", maxMint: "1"},
{tokenName:"defi-Ethereum", tokenSymbol:"dfdETH", initialSupply:"125000000.0", address:"0x56950708Eec09c3fBA7EDa17FB8B95B763e83D2D", maxMint: "10"},
{tokenName:"defi-Cardano", tokenSymbol:"dfdADA", initialSupply:"38000000000.0", address:"0xe33892DB2F7CA97efF0A6C5cB52e0aF98298A299", maxMint: "50000"}
]
*/

/** Goerli */

export const dfdTokens = [{tokenName:"defi-USD", tokenSymbol:"dfdUSD", initialSupply:"1000000000000.0", address:"0xFB4ef31522d65d13A3c63fc57EF74258Ebc3799E", maxMint: "20000"},
{tokenName:"defi-Bitcoin", tokenSymbol:"dfdBTC", initialSupply:"19000000.0", address:"0xA5Cc772B140410AebAA997132916874572Dc7e9c", maxMint: "1"},
{tokenName:"defi-Ethereum", tokenSymbol:"dfdETH", initialSupply:"125000000.0", address:"0x2E2505222B19FB16710B84f3d15B324f29871250", maxMint: "10"},
{tokenName:"defi-Cardano", tokenSymbol:"dfdADA", initialSupply:"38000000000.0", address:"0x02318c0A507aFc87bdFb99a1689E2888364CC9aB", maxMint: "50000"}
];


/** hardhat - Token Factory: 0x5FbDB2315678afecb367f032d93F642f64180aa3 */
/*export const dfdTokens = [{tokenName:"defi-USD", tokenSymbol:"dfdUSD", initialSupply:"1000000000000.0", address:"0x61c36a8d610163660E21a8b7359e1Cac0C9133e1", maxMint: "20000"},
{tokenName:"defi-Bitcoin", tokenSymbol:"dfdBTC", initialSupply:"19000000.0", address:"0x23dB4a08f2272df049a4932a4Cc3A6Dc1002B33E", maxMint: "1"},
{tokenName:"defi-Ethereum", tokenSymbol:"dfdETH", initialSupply:"125000000.0", address:"0x8EFa1819Ff5B279077368d44B593a4543280e402", maxMint: "10"},
{tokenName:"defi-Cardano", tokenSymbol:"dfdADA", initialSupply:"38000000000.0", address:"0x6743E5c6E1B453372507E8dfD6CA53508721425B", maxMint: "50000"}
];
*/

const TokenFactory:React.FC = () => {
    const { currentUser } = useAuth()
    
    const [alertData, setAlertData] = useState({createToken: {show: false, message: '', variant: 'success'}})
    const [loading, setLoading] = useState({createTokenLoading: false})
    const nameField = useRef<HTMLInputElement | null>(null)
    const symbolField = useRef<HTMLInputElement | null>(null)
    const supplyField = useRef<HTMLInputElement | null>(null)
    const [tokenFactoryContract, setTokenFactoryContract] = useState<TokenFactoryContract>()
    const { connect, isConnected, connected, address, provider, signer, block, chainId, balance, chainData, switchChain } = useEthers()
    const [userTokens, setUserTokens] = useState([{maxMint: '', address: '', initialSupply: '', tokenSymbol: '', tokenName: ''}])

    const {mode, menuMode, updateMode, updateMenuMode} = useThemeMode()


    useEffect(() => {
        if (!connected) connect()
    },[])
    useEffect(() => {
        if (currentUser === undefined) return
        //setTokenFactoryContract();
        setTokenFactoryContract(new ethers.Contract(TOKEN_FACTORY_ADDRESS, tokenFactoryABI, signer) as TokenFactoryContract)
        refreshUserTokens()
    }, [connected])


    const refreshUserTokens = async function() { 
        
        if (!currentUser) return
        const userTokensResponse:any = (await getUserTokens(currentUser?.id,currentUser?.api_token)).data
        if (userTokensResponse.result === true) {
            //console.log('success', userTokensResponse.data)
            const tmpTokens = [];
            tmpTokens.push({
                maxMint: '0',
                address: '',
                initialSupply: '',
                tokenSymbol: '',
                tokenName: '',
            })
            for (const each of userTokensResponse.data) {
                tmpTokens.push({
                    maxMint: '0',
                    address: each.tokenAddress,
                    initialSupply: each.initialSupply,
                    tokenSymbol: each.tokenSymbol,
                    tokenName: each.tokenName,
                })
            }
            //console.log(tmpTokens)
            setUserTokens(tmpTokens)
        } else {
            //console.log(userTokensResponse)
        }
    }
    
    /**
     * Create Token button was clicked
     * @returns 
     */
    const createTokenClicked = async function() {
        if (!currentUser || !nameField.current || !symbolField.current || !supplyField.current) return
        setLoading({...loading, createTokenLoading: true})
        //setTimeout(() => { setLoading({...loading, createTokenLoading: false})},1000)

        //clear alert if it's visible
        setAlertData({...alertData, createToken: {show: false, message: '', variant: ''}})

        //Validate token name
        const tokName:string = nameField.current.value
        if (tokName.length < 3 || tokName.length > 16) {
            setAlertData({...alertData, createToken: {show: true, message: 'Token name must be 3-16 characters in length', variant: 'danger'}})
            nameField.current.focus()
            setLoading({...loading, createTokenLoading: false})
            return
        }

        //Validate token symbol
        const tokSymbol:string = symbolField.current.value
        if (tokSymbol.length < 2 || tokSymbol.length > 6) {
            setAlertData({...alertData, createToken: {show: true, message: 'Token symbol must be 2-6 characters in length', variant: 'danger'}})
            symbolField.current.focus()
            setLoading({...loading, createTokenLoading: false})
            return
        }
        
        //convert initial supply to BigNumber
        if (supplyField.current.value === '') supplyField.current.value = '0'
        const tokSupply:BigNumber = ethers.utils.parseEther(supplyField.current.value)
        
        //send transaction to contract
        try {
            const tx = await tokenFactoryContract?.createToken(tokSupply, tokName, tokSymbol)
            //console.log(tx)
            alertData.createToken.message = `Confirming transaction..`
            alertData.createToken.variant = 'info'
            alertData.createToken.show = true
            setAlertData({...alertData})
            const result = await tx.wait()
            //console.log(result)
            const newTokenAddress:string = result.events[0].address
            alertData.createToken.message = `Success! Your new token's contract address is ${newTokenAddress}`
            alertData.createToken.variant = 'success'
            setAlertData({...alertData})
            const newUserTokenDto = new NewUserTokenDto()

            newUserTokenDto.api_token = currentUser.api_token
            newUserTokenDto.user_id = currentUser.id
            newUserTokenDto.txid = tx.hash
            newUserTokenDto.token_address = newTokenAddress
            newUserTokenDto.initial_supply = ethers.utils.formatEther(tokSupply)
            newUserTokenDto.token_name = tokName
            newUserTokenDto.token_symbol = tokSymbol

            const response:any = (await newUserToken(newUserTokenDto)).data
            alertData.createToken.variant = response.result === true ? 'success' : 'danger'
            alertData.createToken.message = response.response
            setAlertData({...alertData})
            refreshUserTokens()
            //submit to database
            //id
            //user_id
            //tokName
            //tokSymbol
            //tokSupply
            //tx.hash
            //result.blockNumber
            //date created
            
        } catch (e: any) {
            if (typeof e === "string") {
                alertData.createToken.message = e
            } else if (e instanceof Error) {
                alertData.createToken.message = e.message
            } else {
                alertData.createToken.message = e.message
            }
            alertData.createToken.variant = 'danger'
            alertData.createToken.show = true
        }
        setAlertData({...alertData})
        setLoading({...loading, createTokenLoading: false})
    }

    const createToken2 = function (name:string, symbol:string, supply:number) {
        if (name.length === 0) {

        }
    }

    const pageTitle = `Token Factory`
// If the user's not connected to Goerli, show them this
/*if (chainId !== '5') return (
    <>
        Connect to Goerli pls {chainId}
    </>
await TokenFactory.connect(addr1).createToken(ethers.utils.parseEther('1000000000000.0'), "defi-USD", "dfdUSD")
await TokenFactory.connect(addr1).createToken(ethers.utils.parseEther('19000000.0'), "defi-Bitcoin", "dfdBTC")
await TokenFactory.connect(addr1).createToken(ethers.utils.parseEther('125000000.0'), "defi-Ethereum", "dfdETH")
await TokenFactory.connect(addr1).createToken(ethers.utils.parseEther('38000000000.0'), "defi-Cardano", "dfdADA")

0x74defE686F2461C1c2aFE5d88c7bA7c56802e075

0x2d9675C55fec1a1cb090f49cBFCA071893b96902
0xa3116E5BEa50A11DBbb7B5B51fbDC3418FD82D2B
0x32f0B893996Bd2E7BE1a8FA2D2FB2d835Ab614aC
0xeA4152C3ccE72bDEbD1150378105A72D456Bbf7a
)*/

// default return

//if (chainId && chainId !== '5') {
if (chainId && chainId !== '5' && chainId !== '31337') {
    return (
        <>
            <PageTitle>{pageTitle}</PageTitle>
            <Container fluid className="">
                <Row>
                    <Col className="text-center">
                        <Button onClick={switchChain}>Switch to Goerli Network</Button>
                    </Col>
                </Row>
            </Container>
        </>
        
    )
}
return (
    <>
        <PageTitle>{pageTitle}</PageTitle>
        <Container fluid className="">
            <Row>
                <Col className="text-center">
                    <div className={`card bg-body-white hoverable card-lg-stretch card-body text-center`} style={{backgroundColor: 'revert'}}>
                        <KTSVG path='/media/icons/duotune/abstract/abs022.svg' className={`svg-icon-primary svg-icon-3x ms-n1 m-4`} />
                        <h1>DeFiDemo Tokens</h1>
                        <p>Mint our ERC-20 tokens on demand</p>
                        <Container fluid className='p-2'>
                            <Row>
                            {
                                dfdTokens.map((token) => (
                                    <DFDToken tokenName={token.tokenName} tokenSymbol={token.tokenSymbol} initialSupply={token.initialSupply} address={token.address} maxMint={token.maxMint} />
                                ))
                            }
                            </Row>
                        </Container>
                    </div>
                </Col>
            </Row>
        </Container>
        <Container fluid className="mb-8">
            <Row>
                <Col className="text-center">
                    <div className={`card bg-body-white hoverable card-lg-stretch mb-xl-8 card-body`} style={{backgroundColor: 'revert'}}>
                        <KTSVG path='/media/icons/duotune/abstract/abs022.svg' className={`svg-icon-primary svg-icon-3x ms-n1 m-4`} />
                        <h1>Your Tokens</h1>
                        <p>Create &amp; manage your ERC-20 tokens (mint, burn, pause)</p>
                        <Container fluid className='p-2'>
                            <Row>
                            <div className='col-xl-3 p-2'>
                                <Container className={`mb-8 card bg-gray-200 hoverable card-xl-stretch mb-xl-8 card-body card-borders  ${mode === 'dark' ? 'card-gradient' : ''} p-2 pt-4 pb-4`} style={{minHeight: `480px`}}>
                                <div className='text-center pb-4 pt-2'>
                                    <img src="/media/logos/user-token.png" alt="DFD" style={{borderRadius: '50%', maxHeight: '64px'}}></img>
                                </div>
                                <p className='fw-bold fs-4 mb-4'>Create ERC-20 token</p>
                                { alertData.createToken.show === true &&
                                    <Alert
                                        variant={alertData.createToken.variant} 
                                        onClose={() => { setAlertData({...alertData, createToken: {show: false, message: alertData.createToken.message, variant: alertData.createToken.variant}})}} 
                                        style={{}}
                                        dismissible>
                                        {alertData.createToken.message}
                                    </Alert>
                                    }
                                    <Row className={`mb-4`}>
                                        <div className="col-xl-12 mb-4">
                                            <p className={`mb-2`}>Token Name</p>
                                            {/*  ref = {ref => inputRef.current.fname = ref} */} 
                                            <Form.Control type="text" placeholder="Bitcoin" ref={nameField} />
                                        </div>
                                        <div className="col-xl-12 mb-4">
                                            <p className={`mb-2`}>Token Symbol</p>
                                            <Form.Control type="text" placeholder="BTC"  ref={symbolField} />
                                        </div>
                                        <div className="col-xl-12 mb-4">
                                            <p className={`mb-2`}>Initial Supply</p>
                                            <Form.Control type="number" placeholder="1000000" min="0" ref={supplyField} />
                                        </div>
                                    </Row>
                                    <Row>
                                        <div className="col-xl-8 offset-xl-2">
                                        <Button onClick={createTokenClicked} disabled={loading.createTokenLoading}>{loading.createTokenLoading === false && <span className='indicator-label'>Create Token</span>}
                                            {loading.createTokenLoading === true && (
                                            <span className='indicator-progress' style={{display: 'block'}}>
                                                Deploying token...
                                                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                                            </span>
                                        )}</Button>
                                        </div>
                                    </Row>
                                </Container>
                            </div>
                            {
                                userTokens.map(token => 
                                    <UserToken token={token}/>
                                )
                            }
                            </Row>
                        </Container>
                    </div>
                </Col>
            </Row>
        </Container>
        {/*<Container fluid className="mb-8">
            <Row>
                <Col className="text-center">
                    <div className={`card bg-body-white hoverable card-lg-stretch mb-xl-8 card-body`} style={{backgroundColor: 'revert'}}>
                    <KTSVG path='/media/icons/duotune/abstract/abs022.svg' className={`svg-icon-primary svg-icon-3x ms-n1 m-4`} />
                        <h1>{pageTitle}</h1>
                        <p className="mb-8">Create ERC-20 token</p>
                        <Container className='p-0' style={{maxWidth: `400px`}}>
                            { alertData.createToken.show === true &&
                            <Alert
                                variant={alertData.createToken.variant} 
                                onClose={() => { setAlertData({...alertData, createToken: {show: false, message: alertData.createToken.message, variant: alertData.createToken.variant}})}} 
                                style={{maxWidth: '400px'}}
                                dismissible>
                                {alertData.createToken.message}
                            </Alert>
                            }
                        </Container>
                        <Container className="mb-8 card bg-gray-200 hoverable card-xl-stretch mb-xl-8 card-body card-borders" style={{minHeight: `400px`}}>
                            <Row className={`mb-4`}>
                                <div className="col-xl-12 mb-4">
                                    <p className={`mb-2`}>Token Name</p>
                                    <Form.Control type="text" placeholder="Bitcoin" ref={nameField} />
                                </div>
                                <div className="col-xl-12 mb-4">
                                    <p className={`mb-2`}>Token Symbol</p>
                                    <Form.Control type="text" placeholder="BTC"  ref={symbolField} />
                                </div>
                                <div className="col-xl-12 mb-4">
                                    <p className={`mb-2`}>Initial Supply</p>
                                    <Form.Control type="number" placeholder="1000000" min="0" ref={supplyField} />
                                </div>
                            </Row>
                            <Row>
                                <div className="col-xl-8 offset-xl-2">
                                <Button onClick={createTokenClicked} disabled={loading.createTokenLoading}>{loading.createTokenLoading === false && <span className='indicator-label'>Create Token</span>}
                                    {loading.createTokenLoading === true && (
                                    <span className='indicator-progress' style={{display: 'block'}}>
                                        Deploying token...
                                        <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                                    </span>
                                )}</Button>
                                </div>
                            </Row>
                        </Container>
                    </div>
                </Col>
            </Row>
        </Container>
                                    */}
    </>
  )
}

export {TokenFactory};