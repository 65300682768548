import { useEffect, useRef, useState }  from "react"
import { PageTitle } from "../../../_metronic/layout/core"
import { useAuth } from "../../modules/auth";
import { Container, Row, Col, Form, Button, Alert } from "react-bootstrap";
import { KTSVG } from "../../../_metronic/helpers";
import { useEthers, shortenCryptoAddress } from "../../modules/ethers/ethersProvider";
import { BigNumber, ethers } from "ethers";
import Dropdown from 'react-bootstrap/Dropdown';
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import { toAbsoluteUrl } from "../../../_metronic/helpers";
import '../../../_metronic/assets/css/cardBorders.css';
import { useThemeMode } from "../../../_metronic/partials";
//token name
//token symbol
//initial supply
//circulating supply
//your balance

//mint / burn
export interface DFDTokenInterface {
    tokenName: string,
    tokenSymbol: string,
    initialSupply: string,
//    circulatingSupply: string,
//    userBalance: string,
    address: string,
    maxMint: string
}

export interface TokenContract extends ethers.Contract {
    balanceOf: (owner: string) => Promise<BigNumber>,
    totalSupply: () => Promise<BigNumber>,
    faucetMint: (amount: BigNumber) => Promise<void>
}
export const tokenABI = ["function balanceOf(address owner) view returns (uint256)",
"function totalSupply() view returns (uint256)",
"function faucetMint(uint256 amount)"];

const DFDToken:React.FC<DFDTokenInterface> = (props) => {
    const { currentUser } = useAuth()
    const [tokenContract, setTokenContract] = useState<TokenContract>()
    const { connect, isConnected, connected, address, provider, signer, block, chainId, balance, chainData } = useEthers()
    const [tokenBalance, setTokenBalance] = useState<string>('0.0')
    const [totalSupply, setTotalSupply] = useState<string>('0.0')
    const [alertData, setAlertData] = useState({tokenAction: {show: false, message: '', variant: 'success'}})
    const [loading, setLoading] = useState({tokenActionLoading: false})
    const {mode, menuMode, updateMode, updateMenuMode} = useThemeMode()


    const reload = () => {
        if (tokenContract !== undefined) refreshToken(tokenContract)
    }
    const refreshToken = async function (contract: TokenContract) {
        if (!currentUser?.crypto_address) return

        setAlertData({...alertData, tokenAction: {show: false, message: '', variant: ''}})
        setLoading({...loading, tokenActionLoading: true})
        try {
            setTokenBalance(ethers.utils.formatEther(await contract.balanceOf(currentUser?.crypto_address)))
            setTotalSupply(ethers.utils.formatEther(await contract.totalSupply()))
        }  catch (e: any) {
            if (typeof e === "string") {
                alertData.tokenAction.message = e
            } else if (e instanceof Error) {
                alertData.tokenAction.message = e.message
            } else {
                alertData.tokenAction.message = e.message
            }
            alertData.tokenAction.variant = 'danger'
            alertData.tokenAction.show = true
        }
        setAlertData({...alertData})
        setLoading({...loading, tokenActionLoading: false})
    }

    const addToWallet = async function () {
        setAlertData({...alertData, tokenAction: {show: false, message: '', variant: ''}})
        setLoading({...loading, tokenActionLoading: true})

        try {
        // wasAdded is a boolean. Like any RPC method, an error may be thrown.
            const wasAdded = await window.ethereum.request({
                method: 'wallet_watchAsset',
                params: {
                type: 'ERC20', // Initially only supports ERC20, but eventually more!
                options: {
                        address: props.address, // The address that the token is at.
                        symbol: props.tokenSymbol, // A ticker symbol or shorthand, up to 5 chars.
                        decimals: 18, // The number of decimals in the token
                        image: 'https://decentralizing.finance/media/logos/favicon.png', // A string url of the token logo
                    },
                },
            });
        } catch (e: any) {
            if (typeof e === "string") {
                alertData.tokenAction.message = e
            } else if (e instanceof Error) {
                alertData.tokenAction.message = e.message
            } else {
                alertData.tokenAction.message = e.message
            }
            alertData.tokenAction.variant = 'danger'
            alertData.tokenAction.show = true
        }
        setAlertData({...alertData})
        setLoading({...loading, tokenActionLoading: false})
    }

    const faucetMint = async function () {
        if (tokenContract === undefined || currentUser === undefined) return

        setAlertData({...alertData, tokenAction: {show: false, message: '', variant: ''}})
        setLoading({...loading, tokenActionLoading: true})
        try {
            const tx:any = await tokenContract.faucetMint(ethers.utils.parseEther(props.maxMint))
            setAlertData({...alertData, tokenAction: {show: true, message: 'Waiting for transaction to confirm', variant: 'info'}})
            const receipt = await tx.wait();
            setTokenBalance(ethers.utils.formatEther(await tokenContract.balanceOf(currentUser?.crypto_address)))
            setTotalSupply(ethers.utils.formatEther(await tokenContract.totalSupply()))
            setAlertData({...alertData, tokenAction: {show: true, message: 'Faucet mint successful!', variant: 'success'}})
        }  catch (e: any) {
            if (typeof e === "string") {
                alertData.tokenAction.message = e
            } else if (e instanceof Error) {
                alertData.tokenAction.message = e.message
            } else {
                alertData.tokenAction.message = e.message
            }
            alertData.tokenAction.variant = 'danger'
            alertData.tokenAction.show = true
            setAlertData({...alertData})
        }
        
        setLoading({...loading, tokenActionLoading: false})
    }

    useEffect(() => {
        (async function() {
            if (!connected) connect()
            const tc:TokenContract = new ethers.Contract(props.address, tokenABI, signer) as TokenContract
            setTokenContract(tc)            
            refreshToken(tc)
        })()
    }, [connected])

// default return
/*
                borderColor: '#6585a738',
                borderTopWidth: '1px',
                borderStyle: 'groove',
                borderBottomWidth: '1px',
                borderLeftWidth: '2px',
                borderRightWidth: '2px',
                */
return (
    <>
        <div className='col-xl-3 p-2'>
            <div className={`mb-8 card bg-gray-200 hoverable card-xl-stretch mb-xl-8 card-body card-borders ${mode === 'dark' ? 'card-gradient' : ''} p-2 pt-4 pb-4`}>
                <div className='text-center mb-2'>
                    <img src="/media/logos/favicon.png" alt="DFD" style={{borderRadius: '50%', maxHeight: '64px'}}></img>
                </div>
                <p className='fw-bold fs-4 mb-0'>{props.tokenName}</p>
                <div className="mx-1 my-4" style={{borderBottom: '1px dashed rgb(94 94 94 / 33%)'}}></div>
                <div className={`table-responsive`}>
                    <table className={`table table-striped table-light table-hover p-0 mb-0`}>
                        <tbody>
                            <tr>
                                <td>
                                    <p className='mb-0 ps-2 pe-2' style={{textAlign: 'left'}}>Address 
                                        <span style={{float: 'right'}}>
                                            <a href={`https://goerli.etherscan.io/token/${props.address}`} target={`_blank`}>{shortenCryptoAddress(props.address)}</a>
                                        </span>
                                    </p>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <p className='mb-0 ps-2 pe-2' style={{textAlign: 'left'}}>Symbol <span style={{float: 'right'}}>{props.tokenSymbol}</span></p>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <p className='mb-0 ps-2 pe-2' style={{textAlign: 'left'}}>Initial Supply <span style={{float: 'right'}}>{props.initialSupply}</span></p>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <p className='mb-0 ps-2 pe-2' style={{textAlign: 'left'}}>Total Supply <span style={{float: 'right'}}>{totalSupply}</span></p>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <p className='mb-0 ps-2 pe-2' style={{textAlign: 'left'}}>Balance
                                        <span style={{float: 'right'}}>
                                            <a href={`https://goerli.etherscan.io/token/${props.address}?a=${currentUser?.crypto_address}`} target={`_blank`}>{tokenBalance}</a>
                                        </span>
                                    </p>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div className="mx-1 my-4" style={{borderBottom: '1px dashed rgb(94 94 94 / 33%)'}}></div>
                {/*
                <ButtonGroup aria-label="Basic example">
                    <Button variant="secondary">Left</Button>
                    <Button variant="secondary">Middle</Button>
                    <Button variant="secondary">Right</Button>
                </ButtonGroup>
                */}
                { alertData.tokenAction.show === true &&
                            <Alert
                                variant={alertData.tokenAction.variant} 
                                onClose={() => { setAlertData({...alertData, tokenAction: {show: false, message: alertData.tokenAction.message, variant: alertData.tokenAction.variant}})}} 
                                style={{maxWidth: '400px'}}
                                dismissible>
                                {alertData.tokenAction.message}
                            </Alert>
                            }
                <Dropdown className='container-fluid p-0'>
                    <Dropdown.Toggle className='' variant="primary">
                        {props.tokenName}
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                        <Dropdown.Item onClick={reload}>Refresh Balances</Dropdown.Item>
                        <Dropdown.Item onClick={faucetMint}>Mint Tokens</Dropdown.Item>
                        <Dropdown.Item onClick={addToWallet}>Add to Wallet</Dropdown.Item>
                    </Dropdown.Menu>
                </Dropdown>
            </div>
        </div>
    </>
  )
}

export { DFDToken };